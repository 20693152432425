import {DataLoading} from '../../../components/data-loading/data-loading'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../store/state/metrics-filter-beta/selectors'
import LoadingState from '../../../values/loading-state-enum'
import {usePagedMetricsBeta} from '../contexts/use-paged-metrics-beta'
import {ContentWrapper} from '../metrics-page.styled'
import {DataActions} from './data-actions/data-actions'
import {MetricsTableViewFormat} from './metrics-table/metrics-table-view-format'
import {MetricsSummaries} from './metrics-summaries/metrics-summaries'
import {BackArrowDiv} from './back-arrow/back-arrow-div'
import {ViewScreenLevelType} from '../../../store/state/metrics-filter-beta/state'
import {MetricsReport} from './metrics-report/metrics-report'

function RenderMainContent({
    screen,
    loading,
}: {
    screen: ViewScreenLevelType
    loading: LoadingState
}) {
    if (loading !== LoadingState.Loaded) {
        return <DataLoading />
    }

    if (screen === 'Table') {
        return <MetricsTableViewFormat />
    }

    if (screen === 'Report') {
        return <MetricsReport />
    }

    // framework or metrics level
    return <MetricsSummaries />
}

export function Content(): JSX.Element {
    const {loadingFilteredDataState, previousPage} = usePagedMetricsBeta()
    const {selectedViewScreenType} = useTypedSelector(metricsBetaFilterSelector)
    return (
        <ContentWrapper id="metrics-page_content-wrapper">
            <DataActions />
            {selectedViewScreenType === 'metrics' && (
                <BackArrowDiv viewScreenLevel={previousPage ?? 'framework'} />
            )}
            <RenderMainContent screen={selectedViewScreenType} loading={loadingFilteredDataState} />
        </ContentWrapper>
    )
}
