import LoadingState from '../../../../values/loading-state-enum'
import {MetricType, MetricsResponse} from './metrics-response'
import {AllCustomerBenchmarksData} from './metrics-summary'
import {ProcessingStatus} from './modal-types'
import {PagedMetricsBetaState} from './paged-metrics-state'

export const DEFAULT_PAGED_METRICS_BETA_STATE: PagedMetricsBetaState = {
    loadingInitialDataState: LoadingState.NotPopulated,
    loadingFilteredDataState: LoadingState.NotPopulated,
    dataMetricsMap: undefined,
    wholeFleetBenchmarkMap: undefined,
    allCustomerBenchmarks: new Array<AllCustomerBenchmarksData>(),
    metricTypesArray: new Array<MetricType>(),
    pagedDataMetricsTableMap: new Map<number, MetricsResponse>(),
    totalNumberOfMetrics: undefined,
    totalNumberOfPages: undefined,
    selectedPage: 0,
    pageSize: 10,
    showFilterBar: false,
    isInactive: false,
    previousPage: undefined,
    previousMetricTypesSelection: undefined,
    highlightedFilerValue: 'None',
    idForMetricDetailsModal: null,
    processingMetricDetailsModal: ProcessingStatus.NOT_PROCESSING,
    findMetricDetailsForModal: undefined,
    modalDetailsExpanded: {
        detailsExpanded: true,
        insightsExpanded: true,
        assetsExpanded: true,
    },
    loadingMetricContextData: LoadingState.NotPopulated,
    metricContextData: undefined,

    // Report level related
    reportLevelData: undefined,
}
