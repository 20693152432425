import styled from 'styled-components'

interface CategoryWrapperProps {
    topCategory: boolean
}

export const CategoryWrapper = styled.div<CategoryWrapperProps>`
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-left: solid ${(props) => (props.topCategory ? '#7ABD3D' : '#BD3D45')} thick;
    padding-left: 8px;
`
