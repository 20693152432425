import {AssetLifeCycle, AssetStatusDropdownOptions} from './asset-status'
import {StyledTippy} from './vessel-card.styled'
import {DefaultTheme} from 'styled-components'
import * as Style from './asset-table.styled'
import {OTAsset} from '../../../store/state/ot-assets/state'
import {RemoveButton} from '../../../components/remove-button/remove-button'
import {openModal} from '../../../store/state/ot-assets/action-creators'
import {ReduxDispatch} from '../../../hooks/useReduxDispatch'
import {ExpandButton} from '../../../components/expand-button/expand-button'
import type {Action} from '../contexts/state/asset-list/actions'
import type {Action as ActionForm} from '../contexts/state/asset-form/actions'
import type {Action as PageAction} from '../contexts/state/page/actions'
import PageActionType from '../contexts/state/page/action-type'
import ActionType from '../contexts/state/asset-list/action-type'
import FormActionType from '../contexts/state/asset-form/action-type'
import {Dispatch} from 'react'
import {EditButton} from '../../../components/edit-button/edit-button'
import * as FormStyle from './asset-creation-form.styled'
import type {AssetForm, AssetOptions} from '../contexts/location-inventory-page-context'

import {FormError} from './form-error'
import type LoadingState from '../../../values/loading-state-enum'
import {Dropdown} from './dropdown'
import {getDropdownOptions} from './asset-creation-form-helper'
import {
    setFormDropdownValue,
    setFormStringValue,
} from '../contexts/state/asset-form/action-creators'

interface RenderProp {
    asset: OTAsset
    isExpanded: boolean
    isBeingEdited: boolean
    formData: AssetForm['state']['formData']
    formFieldError: AssetForm['state']['formFieldError']
    assetOptions: AssetOptions
    assetOptionsLoadingState: LoadingState
    reduxDispatch?: ReduxDispatch
    dispatchAssetList?: Dispatch<Action> | undefined
    dispatchAssetForm?: Dispatch<ActionForm> | undefined
    dispatchPage?: Dispatch<PageAction> | undefined
    isOpenAssetCreationForm?: boolean
}
interface ConfigItem {
    label: string

    render: (prop: RenderProp) => JSX.Element | string
    width: string
}
export type Config = ConfigItem[]

export const assetTableConfig: Config = [
    {
        label: 'Asset name',
        render: ({asset, isBeingEdited, formData, formFieldError, dispatchAssetForm}) =>
            isBeingEdited ? (
                <FormStyle.FormInputContent>
                    <FormStyle.TextInput
                        type="text"
                        value={formData.name}
                        onChange={(e) => {
                            dispatchAssetForm?.(setFormStringValue('name', e.target.value))
                        }}
                        form="asset-creation-form"
                    />
                    <FormError error={formFieldError.name} />
                </FormStyle.FormInputContent>
            ) : (
                <StyledTippy
                    content={asset.name}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <Style.CellDiv>{asset.name}</Style.CellDiv>
                </StyledTippy>
            ),
        width: '14%',
    },
    {
        label: 'Type',
        render: ({
            asset,
            isBeingEdited,
            formData,
            formFieldError,
            assetOptions,
            assetOptionsLoadingState,
            dispatchAssetForm,
        }) =>
            isBeingEdited ? (
                <FormStyle.FormInputContent>
                    <Dropdown
                        options={getDropdownOptions(
                            assetOptions.assetTypes,
                            assetOptionsLoadingState,
                        )}
                        selectedOption={formData.assetTypeID}
                        onChage={(option) =>
                            dispatchAssetForm?.(setFormDropdownValue('assetTypeID', option))
                        }
                        placeholder="Model/Type *"
                        required
                    />
                    <FormError error={formFieldError.assetTypeID} />
                </FormStyle.FormInputContent>
            ) : (
                <StyledTippy
                    content={asset.assetTypeName}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <Style.CellDiv>{asset.assetTypeName}</Style.CellDiv>
                </StyledTippy>
            ),
        width: '18%',
    },
    {
        label: 'Vessel System',
        render: ({
            asset,
            isBeingEdited,
            formData,
            formFieldError,
            assetOptions,
            assetOptionsLoadingState,
            dispatchAssetForm,
        }) =>
            isBeingEdited ? (
                <FormStyle.FormInputContent>
                    <Dropdown
                        options={getDropdownOptions(assetOptions.systems, assetOptionsLoadingState)}
                        selectedOption={formData.systemID}
                        onChage={(option) =>
                            dispatchAssetForm?.(setFormDropdownValue('systemID', option))
                        }
                        placeholder="Vessel system *"
                        required
                    />
                    <FormError error={formFieldError.systemID} />
                </FormStyle.FormInputContent>
            ) : (
                <StyledTippy
                    content={asset.systemName}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <Style.CellDiv>{asset.systemName}</Style.CellDiv>
                </StyledTippy>
            ),
        width: '18%',
    },
    {
        label: 'Zone',
        render: ({
            asset,
            isBeingEdited,
            formData,
            formFieldError,
            assetOptions,
            assetOptionsLoadingState,
            dispatchAssetForm,
        }) =>
            isBeingEdited ? (
                <FormStyle.FormInputContent>
                    <Dropdown
                        options={getDropdownOptions(assetOptions.zones, assetOptionsLoadingState)}
                        selectedOption={formData.zoneID}
                        onChage={(option) =>
                            dispatchAssetForm?.(setFormDropdownValue('zoneID', option))
                        }
                        placeholder="Zone *"
                        required
                    />
                    <FormError error={formFieldError.zoneID} />
                </FormStyle.FormInputContent>
            ) : (
                <StyledTippy
                    content={asset.zoneName}
                    placement="bottom-start"
                    arrow
                    theme={'light-border' as unknown as DefaultTheme}
                >
                    <Style.CellDiv>{asset.zoneName}</Style.CellDiv>
                </StyledTippy>
            ),
        width: '18%',
    },
    {
        label: 'Status',
        render: () => 'Completed',
        width: '10%',
    },
    {
        label: 'Lifecycle status',
        render: ({asset, isBeingEdited, formData, dispatchAssetForm}) =>
            isBeingEdited ? (
                <FormStyle.FormInputContent>
                    <Dropdown
                        options={AssetStatusDropdownOptions}
                        selectedOption={formData.status}
                        onChage={(option) =>
                            dispatchAssetForm?.(setFormDropdownValue('status', option))
                        }
                        placeholder="Lifecycle status *"
                        required
                    />
                </FormStyle.FormInputContent>
            ) : (
                <AssetLifeCycle status={asset.status} />
            ),
        width: '10%',
    },
    {
        label: 'Actions',
        render: ({
            asset,
            isExpanded,
            isBeingEdited,
            reduxDispatch,
            dispatchAssetList,
            dispatchAssetForm,
            dispatchPage,
            isOpenAssetCreationForm,
        }) => {
            return (
                <Style.ActionContent>
                    <EditButton
                        id={`edit-btn-${asset.id}`}
                        isEditing={isBeingEdited || isOpenAssetCreationForm}
                        onClick={() => {
                            // Only do something when no asset
                            // is being edited and asset creation form is not open
                            if (!isBeingEdited && !isOpenAssetCreationForm) {
                                // If asset is being expanded, close it first
                                if (isExpanded) {
                                    dispatchAssetList?.({
                                        type: ActionType.TOGGLE_ASSET_ROW,
                                        payload: {assetId: asset.id, isExpanded: false},
                                    })
                                }
                                // Disable add asset button
                                dispatchPage?.({
                                    type: PageActionType.DISABLE_ADD_BUTTON,
                                    payload: {disabled: true},
                                })
                                dispatchAssetForm?.({
                                    type: FormActionType.SET_IS_EDITING_ID,
                                    payload: {
                                        assetId: asset.id,
                                        asset: asset,
                                    },
                                })
                            }
                        }}
                    />

                    <ExpandButton
                        id={`expand-btn-${asset.id}`}
                        isExpanded={isExpanded}
                        disabled={isBeingEdited}
                        onClick={() => {
                            dispatchAssetList?.({
                                type: ActionType.TOGGLE_ASSET_ROW,
                                payload: {assetId: asset.id, isExpanded: !isExpanded},
                            })
                        }}
                    />

                    <RemoveButton
                        id={`remove-btn-${asset.id}`}
                        disabled={isBeingEdited}
                        onClick={() => {
                            reduxDispatch?.(openModal(asset))
                        }}
                    />
                </Style.ActionContent>
            )
        },
        width: '13%',
    },
]
