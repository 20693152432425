import {MetricType} from '../../../pages/metrics-beta/contexts/types/metrics-response'
import LoadingState from '../../../values/loading-state-enum'
import {NodeValue} from '../../../values/nodes/NodeData'
import {LocationIdType} from '../locations/state'
import {SortValue} from '../vessel-filter/types/vessel-filter-state'

export interface MetricsBetaFilterReduxState {
    loadingState: LoadingState
    locations: Set<LocationIdType> | undefined
    metricTypes: MetricType[] | undefined
    onlyAssetsWithIssues: boolean
    selectedAnalysisPeriod: TimestampFilterType
    orderBy: SortColumnType
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    searchedVesselTerm: string
    vesselSort: SortValue
    filterExpanded: FilterExpandedType
    selectedFilterName: string
    selectedViewScreenType: ViewScreenLevelType
    analysisTypes: AnalysisType
    assetValues: NodeValue[] | undefined
    benchmarkType: BenchmarkTrendsType
}

export interface SortColumnType {
    column: MetricsSortType
    isAscending: boolean
}
export type ViewScreenLevelType = 'framework' | 'metrics' | 'Table' | 'Report'
export type BenchmarkTrendsType = 'All customers' | 'Whole fleet'

export enum MetricsSortType {
    LOCATION_NAME = 'location',
    ASSET_NAME = 'asset',
    POLICY_NAME = 'policy',
    FRAMEWORK = 'framework',
    METRIC = 'metric',
    TIME_WITH_CONTROL_ISSUE = 'timeWithControlIssue',
    BENCHMARK = 'benchmark',
    ASSETS_TO_FIX = 'assetsToFix',
    PERFORMANCE = 'performance',
    TREND_CHANGE = 'window',
    VESSEL_TARGET = 'target',
    NONE = 'none',
}

export enum TimestampFilterType {
    LAST_24_H = '1d',
    LAST_7_DAYS = '7d',
    LAST_30_DAYS = '30d',
    LATEST = 'latest',
    LAST_2_H = '2h',
    ONE_FORTNIGHT = '14d',
    ONE_QUARTER = '71d',
    ONE_YEAR = '365d',
}
export enum AnalysisType {
    TRENDS = 'trends',
    WORST_PERFORMING_VESSELS = 'worstPerformingLocations',
    SCORECARD = 'assetStatus',
    BENCHMARK = 'benchmark',
    TARGET = 'target',
}

export interface FilterExpandedType {
    metricsExpanded: boolean
    periodExpanded: boolean
    assetsExpanded: boolean
    vesselExpanded: boolean
}

export enum FilterBarType {
    MetricsType = 'Metrics selection',
    AnalysisSelection = 'Analysis selection',
    AssetSelection = 'Asset selection',
    Vessels = 'Vessels',
}

export const DEFAULT_METRICS_BETA_FILTER_STATE: MetricsBetaFilterReduxState = {
    loadingState: LoadingState.NotPopulated,
    locations: undefined,
    metricTypes: undefined,
    onlyAssetsWithIssues: true,
    selectedAnalysisPeriod: TimestampFilterType.LAST_30_DAYS,
    orderBy: {column: MetricsSortType.LOCATION_NAME, isAscending: true},
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    searchedVesselTerm: '',
    vesselSort: {
        field: 'vessel-name',
        direction: 'desc',
    },
    filterExpanded: {
        metricsExpanded: true,
        periodExpanded: true,
        vesselExpanded: true,
        assetsExpanded: true,
    },
    selectedFilterName: 'Saved filters',
    selectedViewScreenType: 'framework',
    analysisTypes: AnalysisType.SCORECARD,
    assetValues: undefined,
    benchmarkType: 'Whole fleet',
}

export interface MetricsBetaFilter {
    locations: LocationIdType[] | undefined
    metricTypes: MetricType[] | undefined
    onlyAssetsWithIssues: boolean
    selectedAnalysisPeriod: TimestampFilterType
    orderBy: SortColumnType
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    selectedViewScreenType: ViewScreenLevelType
    analysisTypes: AnalysisType
    assetValues: NodeValue[] | undefined
    filterExpanded: FilterExpandedType
}

export const DEFAULT_METRICS_BETA_FILTER: MetricsBetaFilter = {
    locations: undefined,
    metricTypes: undefined,
    onlyAssetsWithIssues: true,
    selectedAnalysisPeriod: TimestampFilterType.LAST_30_DAYS,
    orderBy: {column: MetricsSortType.LOCATION_NAME, isAscending: true},
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
    selectedViewScreenType: 'framework',
    analysisTypes: AnalysisType.SCORECARD,
    assetValues: undefined,
    filterExpanded: {
        metricsExpanded: true,
        periodExpanded: true,
        vesselExpanded: true,
        assetsExpanded: true,
    },
}
