export function BehaviourIcon() {
    return (
        <svg
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="21.2371" cy="20" rx="20.5965" ry="20" fill="#19ACDB" fillOpacity="0.21" />
            <path
                d="M29.6995 20.0009H26.2807L23.7166 27.6932L18.5884 12.3086L16.0243 20.0009H12.6055"
                stroke="#19ACDB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
