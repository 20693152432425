import createCachedSelector from 're-reselect'
import {nodesSelector} from '../../../../../store/state/nodes/selectors'
import AppState from '../../../../../store/types/app-state'
import {GuidType} from '../../../../../values/generic-type-defintions'

export const currentAssetValueReselector = createCachedSelector(
    [nodesSelector, (_state: AppState, assetId: GuidType | null) => assetId],
    (nodes, assetId) => {
        if (!assetId) {
            return {
                nodeValue: undefined,
                nodeAllias: '',
            }
        }
        const nodeData = nodes && nodes?.get(assetId)
        if (!nodeData) {
            return {
                nodeValue: undefined,
                nodeAllias: '',
            }
        }
        return {
            nodeValue: nodeData?.value,
            nodeAllias: nodeData?.alias ?? '',
        }
    },
)((_state, assetId) => assetId)
