import produce from 'immer'
import {ModalEditAssetState} from '../types/modal-edit-asset-state'
import {ActionType} from './action-type'
import {AllActions} from './actions'
import {LoggingOutAction} from '../../../../../store/state/session-data/actions'

export const modalEditAssetReducer = produce(
    (draft: ModalEditAssetState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.SET_CHANGE_ASSET_VALUE:
                draft.newAssetValue = action.payload
                break
            case ActionType.SET_CHANGE_ALLIAS:
                draft.newAllias = action.payload
                break
            case ActionType.SET_SAVE_PROCESSING_STATUS:
                draft.isProcessingSave = action.payload
                break
            /* istanbul ignore next */
            default:
                break
        }
        return draft
    },
)
