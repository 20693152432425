import type {Dispatch} from 'react'
import type {CreateOTAssetReq} from '../../../../../store/state/ot-assets/state'
import {defaultLocationInventoryContextState} from '../../location-inventory-page-context'
import type {
    AssetForm,
    FormDropdownField,
    FormStringField,
    MultiselectField,
} from '../../location-inventory-page-context'
import ActionType from './action-type'
import * as Actions from './actions'
import type {Action as AssetFormAction} from './actions'
import type {Action as PageAction} from '../page/actions'
import {REST} from '../../../../..'
import axios from 'axios'
import type {Dispatch as ReduxDispatch} from 'redux'
import _ from 'lodash'
import type {DropdownOption} from '../../../components/dropdown'
import PageActionType from '../page/action-type'

const BASE_URL = '/ot-inventory/api/v1/locations'

export function successCreateAsset(
    payload: Actions.SuccessCreateOTAsset['payload'],
): Actions.SuccessCreateOTAsset {
    return {
        type: ActionType.CREATE_ASSET_SUCCESS,
        payload,
    }
}

export function setFormSubmitError(error: string): Actions.SetOTAssetFormSubmitError {
    return {
        type: ActionType.SET_ASSET_FORM_SUBMIT_ERROR,
        payload: {error},
    }
}

export function setFormStringValue(
    field: FormStringField,
    value: string,
): Actions.SetStringFormValue {
    return {
        type: ActionType.SET_FORM_STRING_VALUE,
        payload: {
            field,
            value,
        },
    }
}

export function setFormDropdownValue(
    field: FormDropdownField,
    value: DropdownOption,
): Actions.SetDropdownFormValue {
    return {
        type: ActionType.SET_FORM_DROPDOWN_VALUE,
        payload: {
            field,
            value,
        },
    }
}

export function setFormDropdownValues(
    field: MultiselectField,
    value: DropdownOption,
): Actions.SetDropdownFormValues {
    return {
        type: ActionType.SET_FORM_DROPDOWN_VALUES,
        payload: {
            field,
            value,
        },
    }
}

export function createAsset(
    locationID: string,
    dispatch: Dispatch<AssetFormAction> | undefined,
    reduxDispatch: ReduxDispatch,
    asset: CreateOTAssetReq,
) {
    if (!dispatch || !reduxDispatch) {
        throw new Error(
            'dispatch or reduxDispatch is undefined, please ensure this is called in LocationInventoryPageProvider',
        )
    }

    // Set form button loading state
    dispatch({type: ActionType.CREATE_ASSET_REQUEST})

    REST.post(`${BASE_URL}/${locationID}/assets`, asset)
        .then((response) => {
            dispatch(
                successCreateAsset({
                    locationID,
                    data: response.data,
                    reduxDispatch: reduxDispatch,
                }),
            )
        })
        .catch((error) => {
            const errorMsg = 'An error occurred while creating the asset'

            if (!axios.isAxiosError(error)) {
                dispatch(setFormSubmitError(errorMsg))
                return
            }

            if (!error.response) {
                dispatch(setFormSubmitError(errorMsg))
            }

            if (error.response?.status === 400) {
                const formErrors: AssetForm['state']['formFieldError'] = _.cloneDeep(
                    defaultLocationInventoryContextState.assetForm.state.formFieldError,
                )
                // This is to scaffold the error object of each
                // custom field that is present in the request
                formErrors.customFields = asset.customFields.map(() => ({name: '', value: ''}))

                if (error.response?.data?.details === undefined) {
                    dispatch(setFormSubmitError(error.response.data.message ?? errorMsg))
                    return
                }

                error.response.data.details.forEach((detail: {field: string; error: string}) => {
                    const isCustomFieldErrorRegex = /customFields\/(\d+)\/(name|value)/

                    const customFieldError = detail.field.match(isCustomFieldErrorRegex)

                    if (detail.field && formErrors[detail.field] !== undefined) {
                        formErrors[detail.field] = detail.error
                    } else if (customFieldError !== null) {
                        const indexStr = customFieldError[1]
                        const field = customFieldError[2]
                        // Somehow our regex is wrong or
                        // server is now returning it in different format
                        if (!indexStr || !field) {
                            throw new Error(
                                'Unexpected field format in custom field error from server',
                            )
                        }
                        if (field !== 'name' && field !== 'value') {
                            throw new Error(
                                'Unexpected field identifier in custom field error from server',
                            )
                        }
                        const index = parseInt(indexStr)
                        formErrors.customFields[index][field] = detail.error
                    } else {
                        throw new Error(
                            `Unexpected error field in asset creation response ${detail.field}: ${detail.error}`,
                        )
                    }
                })

                dispatch({type: ActionType.SET_ASSET_FORM_ERRORS, payload: formErrors})
                return
            }

            if (error.response?.data?.message) {
                dispatch(setFormSubmitError(error.response.data.message))
                return
            }

            dispatch(setFormSubmitError(errorMsg))

            return
        })
}

export function editAsset(
    locationID: string,
    assetID: string,
    dispatch: Dispatch<AssetFormAction> | undefined,
    pageDispatch: Dispatch<PageAction> | undefined,
    reduxDispatch: ReduxDispatch,
    asset: CreateOTAssetReq,
) {
    if (!dispatch || !reduxDispatch || !pageDispatch) {
        throw new Error(
            'dispatch or reduxDispatch or pageDispatch is undefined, please ensure this is called in LocationInventoryPageProvider',
        )
    }

    // Set form button loading state
    dispatch({type: ActionType.EDIT_ASSET_REQUEST})

    REST.put(`${BASE_URL}/${locationID}/assets/${assetID}`, asset)
        .then((response) => {
            dispatch({
                type: ActionType.EDIT_ASSET_SUCCESS,
                payload: {
                    data: response.data,
                    reduxDispatch: reduxDispatch,
                },
            })
            // Enable add button
            pageDispatch({type: PageActionType.DISABLE_ADD_BUTTON, payload: {disabled: false}})
        })
        .catch((error) => {
            const errorMsg = 'An error occurred while editing the asset'

            if (!axios.isAxiosError(error)) {
                dispatch(setFormSubmitError(errorMsg))
                return
            }

            if (!error.response) {
                dispatch(setFormSubmitError(errorMsg))
            }

            if (error.response?.status === 400) {
                const formErrors: AssetForm['state']['formFieldError'] = _.cloneDeep(
                    defaultLocationInventoryContextState.assetForm.state.formFieldError,
                )
                // This is to scaffold the error object of each
                // custom field that is present in the request
                formErrors.customFields = asset.customFields.map(() => ({name: '', value: ''}))

                if (error.response?.data?.details === undefined) {
                    dispatch?.({
                        type: ActionType.SET_ASSET_FORM_SUBMIT_ERROR,
                        payload: {error: error.response.data.message ?? errorMsg},
                    })
                    return
                }

                error.response.data.details.forEach((detail: {field: string; error: string}) => {
                    const isCustomFieldErrorRegex = /customFields\/(\d+)\/(name|value)/

                    const customFieldError = detail.field.match(isCustomFieldErrorRegex)

                    if (detail.field && formErrors[detail.field] !== undefined) {
                        formErrors[detail.field] = detail.error
                    } else if (customFieldError !== null) {
                        const indexStr = customFieldError[1]
                        const field = customFieldError[2]
                        // Somehow our regex is wrong or
                        // server is now returning it in different format
                        if (!indexStr || !field) {
                            throw new Error(
                                'Unexpected field format in custom field error from server',
                            )
                        }
                        if (field !== 'name' && field !== 'value') {
                            throw new Error(
                                'Unexpected field identifier in custom field error from server',
                            )
                        }
                        const index = parseInt(indexStr)
                        formErrors.customFields[index][field] = detail.error
                    } else {
                        // There will be an undefined field in the response
                        // which is revision.
                        // We are not handling the revision field error
                        // yet, so we're just setting a generic error message
                        // that tells user to refresh the page

                        dispatch(
                            setFormSubmitError(
                                'Your asset information is out of date. Please refresh the page and try again.',
                            ),
                        )
                    }
                })

                dispatch({type: ActionType.SET_ASSET_FORM_ERRORS, payload: formErrors})
                return
            }

            if (error.response?.data?.message) {
                dispatch(setFormSubmitError(error.response.data.message))
                return
            }

            dispatch(setFormSubmitError(errorMsg))

            return
        })
}
