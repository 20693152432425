import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip} from 'recharts'
import {IssuesRaised} from './types/metric-report-types'
import {MetricType} from '../../contexts/types/metrics-response'
import {MaintenanceProcessColor, CrewBehaviourColor, SummaryRadarContainer} from './common.styled'
import {RadarChartDimensions} from './metrics-report.styled'

export interface RadarChartProps {
    data: {
        [label: string]: number
    }
    color: string
    height: number
    width: number
}

export interface IssuesRaisedProps {
    issuesRaised: Partial<IssuesRaised>
}

export function MetricsIssuesBehaviourRadarChart(props: IssuesRaisedProps) {
    const MetricTypeToLabelMap: {[key: string]: string} = {
        [MetricType.USB_STORAGE]: 'USB Storage',
        [MetricType.MOBILE_DEVICES]: 'Mobile Devices',
        [MetricType.HOTSPOT_MODE]: 'Hotspot Mode',
        [MetricType.WIRELESS_CONNECTIVITY]: 'Wireless Connectivity',
        [MetricType.ADMIN_LOGON]: 'Admin Logon',
        [MetricType.PORTABLE_SOFTWARE]: 'Portable Software',
    }

    const data = Object.keys(props.issuesRaised).reduce(
        (acc, key) => {
            const label = MetricTypeToLabelMap[key]
            if (label) {
                acc[label] = props.issuesRaised[key as MetricType]!.totalAssetsWithIssue
            }
            return acc
        },
        {} as {[label: string]: number},
    )

    return (
        <SummaryRadarContainer>
            <MetricsRadarChart
                data={data}
                color={CrewBehaviourColor}
                height={RadarChartDimensions.height}
                width={RadarChartDimensions.width}
            />
        </SummaryRadarContainer>
    )
}

export function MetricsIssuesMaintenanceProtectionRadarChart(props: IssuesRaisedProps) {
    const MetricTypeToLabelMap: {[key: string]: string} = {
        [MetricType.MALWARE_DEFINITION]: 'Malware Definition',
        [MetricType.OS_SECURITY_PATCHES]: 'OS Security Patches',
        [MetricType.ASSET_ONLINE]: 'Asset Online',
        [MetricType.MALWARE_DETECTION]: 'Malware Detection',
        [MetricType.SUPPORTED_OS]: 'Supported OS',
        [MetricType.PRIVILEGED_ACCOUNTS]: 'Privileged Accounts',
        [MetricType.ACCESS_CONTROLS]: 'Access Controls',
        [MetricType.REMOVABLE_CONTROLS]: 'Removable Controls',
        [MetricType.SECURITY_TOOLS]: 'Security Tools',
    }

    const data = Object.keys(props.issuesRaised).reduce(
        (acc, key) => {
            const label = MetricTypeToLabelMap[key]
            if (label) {
                acc[label] = props.issuesRaised[key as MetricType]!.totalAssetsWithIssue
            }
            return acc
        },
        {} as {[label: string]: number},
    )

    return (
        <SummaryRadarContainer>
            <MetricsRadarChart
                data={data}
                color={MaintenanceProcessColor}
                height={RadarChartDimensions.height}
                width={RadarChartDimensions.width}
            />
        </SummaryRadarContainer>
    )
}

function MetricsRadarChart(props: RadarChartProps) {
    const valueMax = Math.max(...Object.values(props.data))
    const angle = 360 / Object.keys(props.data).length
    const tickCount = 5
    const data = Object.keys(props.data).map((key) => ({
        key: key,
        value: props.data[key],
    }))

    return (
        <RadarChart data={data} height={props.height} width={props.width}>
            <PolarGrid />
            <PolarAngleAxis dataKey="key" />
            <PolarRadiusAxis
                scale={'linear'}
                angle={angle}
                domain={[0, valueMax]}
                tickCount={tickCount}
            />
            <Radar
                dataKey="value"
                stroke={props.color}
                fill={props.color}
                fillOpacity={0.3}
                dot={true}
            />
            <Tooltip />
        </RadarChart>
    )
}
