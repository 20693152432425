import {ExpandPanelButton} from '../expand-panel-button/expand-panel-button'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {MetricsDetailType} from '../../../../contexts/types/modal-types'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'
import {Panel} from '../_styled/panel-section.styled'
import * as Styled from './assets-affected.styled'
import {AssetStateOverTimestamp, AssetsAffected} from '../../../../contexts/types/metrics-summary'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {Button, IconWrapper} from '../expand-panel-button/expand-panel-button.styled'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../../store/state/metrics-filter-beta/selectors'
import moment from 'moment'
import {TimestampFilterType} from '../../../../../../store/state/metrics-filter-beta/state'
import {getStartAndEndDate} from '../../../../contexts/context-data-helper'
import {DataCell} from '../shared/data-cell'
import {formatDateNumber} from '../../../data-helper'

export function AssetsAffectedSection(): JSX.Element {
    const {modalDetailsExpanded, isProcessingMetricDetailsModal} = usePagedMetricsBeta()
    return (
        <Panel>
            <ExpandPanelButton headingRow={MetricsDetailType.Assets} />
            {modalDetailsExpanded.assetsExpanded &&
                (!isProcessingMetricDetailsModal ? <DataLoading /> : <AssetsAffectedList />)}
        </Panel>
    )
}

function AssetsAffectedList(): JSX.Element {
    const {findMetricDetailsForModal, displayMetricsContextData} = usePagedMetricsBeta()

    useEffect(() => {
        displayMetricsContextData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (findMetricDetailsForModal?.assetsAffected?.length === 0) {
        return <Styled.NoAssetsText>No affected assets found</Styled.NoAssetsText>
    }
    return (
        <Styled.AssetsListWrapper id="assets-list-wrapper">
            {findMetricDetailsForModal?.assetsAffected?.map((asset, index) => (
                <AssetSection assetAffected={asset} key={`${asset}-${index}`} />
            ))}
        </Styled.AssetsListWrapper>
    )
}

function AssetSection({assetAffected}: {assetAffected: AssetsAffected}): JSX.Element {
    const [expandAssetView, setExpandAssetView] = useState(false)
    return (
        <Styled.AssetSection>
            <Styled.AssetWrapper>
                <AssetItem asset={assetAffected} />
                <ExpandAssetButton
                    expandAssetView={expandAssetView}
                    setExpandAssetView={setExpandAssetView}
                    assetAffected={assetAffected.asset}
                />
            </Styled.AssetWrapper>
            <MetricBar assetStateDynamics={assetAffected.assetStateDynamics} />
            {expandAssetView && <GetExpandMetricContextSection assetId={assetAffected.asset} />}
        </Styled.AssetSection>
    )
}

interface AssetItemProps {
    asset: AssetsAffected
}

export function AssetItem({asset}: AssetItemProps): JSX.Element {
    return <Styled.AssetSquare>{asset.assetName}</Styled.AssetSquare>
}

interface ExpandAssetButtonProps {
    expandAssetView: boolean
    setExpandAssetView: Dispatch<SetStateAction<boolean>>
    assetAffected: string
}
function ExpandAssetButton({
    expandAssetView,
    setExpandAssetView,
    assetAffected,
}: ExpandAssetButtonProps): JSX.Element {
    return (
        <Button
            onClick={() => {
                setExpandAssetView(!expandAssetView)
            }}
            id={`expand-button-${assetAffected}`}
        >
            <IconWrapper>
                <Icon
                    glyph={expandAssetView ? 'DropdownUp' : 'DropdownDown'}
                    height={15}
                    width={15}
                />
            </IconWrapper>
        </Button>
    )
}
function MetricBar({
    assetStateDynamics,
}: {
    assetStateDynamics: AssetStateOverTimestamp[]
}): JSX.Element | null {
    const {selectedAnalysisPeriod} = useTypedSelector(metricsBetaFilterSelector)
    const {startDate, endDate} = getStartAndEndDate(selectedAnalysisPeriod)
    const {blockCounts} = getTickIntervals(selectedAnalysisPeriod)

    const blockDuration = (moment(endDate).unix() - moment(startDate).unix()) / blockCounts

    const getBlockColor = (blockIndex: number, previousColor: string) => {
        const blockStart = moment(startDate).add(blockDuration * blockIndex, 'seconds')
        const blockEnd = blockStart.clone().add(blockDuration, 'seconds')

        // Get all timestamps that fall within this block
        const timestampsInBlock = assetStateDynamics.filter((item: AssetStateOverTimestamp) => {
            const timestamp = moment.unix(item.timestamp)
            return timestamp.isSameOrAfter(blockStart) && timestamp.isBefore(blockEnd)
        })

        if (timestampsInBlock.length > 0) {
            // Calculate the duration from the block start to the first timestamp
            const durations = timestampsInBlock.map((item, index) => {
                if (index === 0) {
                    // Duration from block start to the first timestamp
                    return item.timestamp - blockStart.unix()
                }
                // Duration between consecutive timestamps
                return item.timestamp - timestampsInBlock[index - 1].timestamp
            })

            // Find the index of the maximum duration
            const maxDurationIndex = durations.indexOf(Math.max(...durations))
            const recordWithMaxDuration = timestampsInBlock[maxDurationIndex]

            const color = recordWithMaxDuration.value === 'ISSUE' ? 'red' : 'green'

            return {
                color: color,
                timestamp: recordWithMaxDuration.timestamp,
            }
        } else {
            return {
                color: previousColor, // Continue with the previous color
                timestamp: blockStart.format(),
            }
        }
    }

    // Ensure we have at least one record
    if (assetStateDynamics.length === 0) {
        return null
    }

    // Set the initial color opposite to the first record's value
    let previousColor = assetStateDynamics[0].value === 'ISSUE' ? 'green' : 'red'

    return (
        <>
            <Styled.TimelineWrapper>
                {[...Array(blockCounts)].map((_, index) => {
                    const blockColorInfo = getBlockColor(index, previousColor)
                    previousColor = blockColorInfo.color // Update previous color for the next block
                    const timeBlockTitle = `Timestamp: ${formatDateNumber(blockColorInfo?.timestamp ?? '')}
\nStatus: ${blockColorInfo.color === 'red' ? 'ISSUE' : 'OK'}`
                    return (
                        <Styled.TimeBlock
                            key={index}
                            blockColour={blockColorInfo.color}
                            title={timeBlockTitle}
                        />
                    )
                })}
            </Styled.TimelineWrapper>
            <CustomXAxis />
        </>
    )
}

function CustomXAxis(): JSX.Element {
    const {selectedAnalysisPeriod} = useTypedSelector(metricsBetaFilterSelector)
    const {startDate, endDate} = getStartAndEndDate(selectedAnalysisPeriod)

    const xTicks = generateTicks(startDate, endDate, selectedAnalysisPeriod)
    const tickWidth = 370 / (xTicks?.length - 1)
    const oneTick = xTicks?.length === 1
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '370px',
            }}
        >
            {xTicks.map((tick, index) => (
                <div
                    key={index}
                    style={{
                        position: 'relative',
                        width: tickWidth,
                        opacity: 0.5,
                    }}
                >
                    <Styled.XTick
                        index={index}
                        lastItem={index === xTicks.length - 1}
                        oneTick={oneTick}
                    >
                        <div
                            style={{
                                height: '8px',
                                width: '2px',
                                border: '1px solid black',
                                opacity: '0.2',
                                marginLeft: '50%',
                            }}
                        />
                        {tick}
                    </Styled.XTick>
                </div>
            ))}
        </div>
    )
}

function GetExpandMetricContextSection({assetId}: {assetId: string}): JSX.Element {
    const {loadedMetricContextData} = usePagedMetricsBeta()

    return (
        <>
            {loadedMetricContextData ? (
                <MetricContextData assetId={assetId} />
            ) : (
                <LoadingMetricContextData />
            )}
        </>
    )
}

function LoadingMetricContextData(): JSX.Element {
    return (
        <>
            <DataLoading size={80} />
            <Styled.AssetsText>Fetching data - please wait</Styled.AssetsText>
        </>
    )
}

function MetricContextData({assetId}: {assetId: string}): JSX.Element {
    const {metricContextData} = usePagedMetricsBeta()
    const getMetricContextDataForGivenAsset = metricContextData?.get(assetId)
    if (!getMetricContextDataForGivenAsset) {
        return <Styled.AssetsText>No context data</Styled.AssetsText>
    }
    const filterKeys = (contextData: Record<string, string>) => {
        const keysToFilter = ['from', 'to', 'asset', 'location']
        return Object.entries(contextData).filter(([key]) => !keysToFilter.includes(key))
    }
    return (
        <Styled.DetailedAreaWrapper>
            {getMetricContextDataForGivenAsset?.map((contextData, index) => (
                <Styled.DetailsAreaContent key={index}>
                    {filterKeys(contextData).map(([key, value]) => (
                        <DataCell key={key} label={`${key}:`}>
                            {value}
                        </DataCell>
                    ))}
                    <DataCell label="First seen:">{formatDateNumber(contextData.from)}</DataCell>
                    <DataCell label="Last seen:">{formatDateNumber(contextData.to)}</DataCell>
                </Styled.DetailsAreaContent>
            ))}
        </Styled.DetailedAreaWrapper>
    )
}

const getTickIntervals = (period: TimestampFilterType) => {
    switch (period) {
        case 'latest':
            return {interval: 0, unit: 'latest', format: 'HH:mm', blockCounts: 1}
        case '2h':
            return {interval: 1, unit: 'hours', format: 'HH:mm', blockCounts: 30}
        case '1d':
            return {interval: 3, unit: 'hours', format: 'HH:mm', blockCounts: 24}
        case '7d':
            return {interval: 1, unit: 'days', format: 'DD/MM', blockCounts: 28}
        case '30d':
            return {interval: 5, unit: 'days', format: 'DD/MM', blockCounts: 30}
        case '14d':
            return {interval: 2, unit: 'days', format: 'DD/MM', blockCounts: 28}
        case '71d':
            return {interval: 10, unit: 'days', format: 'DD/MM', blockCounts: 34}
        case '365d':
            return {interval: 2, unit: 'months', format: 'MM/YY', blockCounts: 31}
        default:
            return {interval: 1, unit: 'days', format: 'DD/MM', blockCounts: 1}
    }
}

// Generate ticks based on the selected period
const generateTicks = (startDate: Date, endDate: Date, period: TimestampFilterType): string[] => {
    const {interval, unit, format} = getTickIntervals(period)
    const ticks: string[] = []
    const currentDate = moment(startDate)
    if (unit === 'latest') {
        ticks.push(currentDate.format(format))
    } else {
        while (currentDate <= moment(endDate)) {
            ticks.push(currentDate.format(format))
            currentDate.add(interval, unit as moment.unitOfTime.DurationConstructor)
        }
    }

    return ticks
}
