import styled from 'styled-components'
import {mediumSmallFont} from '../../../../theme/font-styles'
import {Colors} from '../../../../theme/colours'
import {spacing} from '../../../../theme/spacing'

export const ProtectiveControlColor = '#192CDB'
export const MaintenanceProcessColor = '#6E19DB'
export const CrewBehaviourColor = '#19ACDB'

export const SummaryWidgetLayout = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
`

export const SummaryCategory = styled.div`
    ${mediumSmallFont()}
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`

export const SummaryDescription = styled.div`
    text-align: center;
    text-wrap: nowrap;
`

export const SummaryDisplayNumber = styled.span<{color: string}>`
    color: ${(prop) => prop.color};
    font-size: 20px;
    font-weight: bold;
`

export const SummaryMarginedText = styled.span`
    margin: 0 ${spacing(1)} 0 ${spacing(1)};
`

export const SummaryBadge = styled.span<{color: string; backgroundColor: string}>`
    border-radius: 4px;
    padding: 0 ${spacing(1)} 0 ${spacing(1)};
    color: ${(prop) => prop.color};
    background-color: ${(prop) => prop.backgroundColor};
`

export const SummaryBadgeGreen = styled(SummaryBadge).attrs(() => ({
    color: Colors.green1,
    backgroundColor: '#e4f2d8',
}))``

export const SummaryPercentage = styled.sup.attrs(() => ({children: '%'}))`
    font-size: 15px;
`
export const SummaryText = styled.div`
    font-weight: 600;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    text-wrap: nowrap;
`

export const SummaryRadarContainer = styled.div`
    margin: auto;
`
