import {
    CrewBehaviourColor,
    SummaryDisplayNumber,
    SummaryCategory,
    SummaryWidgetLayout,
    MaintenanceProcessColor,
    SummaryPercentage,
    ProtectiveControlColor,
    SummaryText,
    SummaryDescription,
    SummaryMarginedText,
} from './common.styled'
import {BehaviourIcon} from './icons/behaviours'
import {MaintenanceIcon} from './icons/maintenance'
import {ProtectionIcon} from './icons/protection'
import {AssetStatus} from './types/metric-report-types'

interface Props {
    assetStatus: AssetStatus
}

export function AssetStatusWidget({assetStatus}: Props) {
    const protection = assetStatus?.protection
    const maintenance = assetStatus?.maintenance
    const behaviour = assetStatus?.behaviour

    // Protection
    const pOk = protection?.totalAssetsOk ?? 0
    const pIssue = protection?.totalAssetsWithIssue ?? 0
    const pTotal = pOk + pIssue
    const protectionValue = pTotal != 0 ? Math.round((pOk / pTotal) * 100) : 0

    // Maintenance
    const mOk = maintenance?.totalAssetsOk ?? 0
    const mIssue = maintenance?.totalAssetsWithIssue ?? 0
    const mTotal = mOk + mIssue
    const maintenanceValue = mTotal != 0 ? Math.round((mOk / mTotal) * 100) : 0

    // behaviour
    const bOk = behaviour?.totalAssetsOk ?? 0
    const bIssue = behaviour?.totalAssetsWithIssue ?? 0
    const bTotal = bOk + bIssue
    const behaviourValue = bTotal != 0 ? Math.round((bOk / bTotal) * 100) : 0

    return (
        <SummaryWidgetLayout>
            <SummaryCategory>
                <ProtectionIcon />
                <SummaryDescription>Protective Control</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={ProtectiveControlColor}>
                        {protectionValue}
                        <SummaryPercentage />
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>ASSET(S) ARE OK</SummaryMarginedText>
                </SummaryText>
            </SummaryCategory>

            <SummaryCategory>
                <MaintenanceIcon />
                <SummaryDescription>Maintenance Process</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={MaintenanceProcessColor}>
                        {maintenanceValue}
                        <SummaryPercentage />
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>ASSET(S) ARE OK</SummaryMarginedText>
                </SummaryText>
            </SummaryCategory>
            <SummaryCategory>
                <BehaviourIcon />
                <SummaryDescription>Crew Behaviour</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={CrewBehaviourColor}>
                        {behaviourValue}
                        <SummaryPercentage />
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>ASSET(S) ARE OK</SummaryMarginedText>
                </SummaryText>
            </SummaryCategory>
        </SummaryWidgetLayout>
    )
}
