import {VesselTag} from '../../../../../components/vessel-tag/vessel-tag'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {TagsUtils} from '../../../../../utils/Utils'
import {NoDataContent} from '../../../../dashboard-v2/components/general/content-area/no-data/no-data-content'
import {getWidth} from '../../../../dashboard-v2/components/tags-component/data-helpers'
import * as Styled from './tags-table-body.styled'

export function TagsTableContent({rows}: {rows: string[][]}): JSX.Element {
    const {width} = useDimensions()
    if (Object.keys(rows).length === 0) {
        return (
            <div style={{padding: '30px'}}>
                <NoDataContent text="No tag data" />
            </div>
        )
    }

    return (
        <div>
            <Styled.TableDataGrid width={width} showReducedGrid={false}>
                {rows.map((row, i) => (
                    <TableRow key={i} index={i} data={row} />
                ))}
            </Styled.TableDataGrid>
        </div>
    )
}

type TableRowProps = {
    index: number
    data: string[]
}

function TableRow({index, data}: TableRowProps): JSX.Element {
    const gridRow = index + 1

    return (
        <>
            <Styled.TableDataRow gridRow={gridRow} id={`data-row-${data[0]}`} />
            {data.map((r, i) =>
                i !== 0 ? (
                    <Styled.TableDataCell gridColumn={i + 2} gridRow={gridRow} key={i} centered>
                        {r}
                    </Styled.TableDataCell>
                ) : (
                    <Styled.TableDataCell
                        key={i}
                        gridRow={gridRow}
                        gridColumn={i + 2}
                        id={`tagName-value-${data[0]}`}
                    >
                        <VesselCol tagName={r} idx={i} />
                    </Styled.TableDataCell>
                ),
            )}
        </>
    )
}

function VesselCol({tagName, idx}: {tagName: string; idx: number}) {
    const {width} = useDimensions()
    const maxWidth = getWidth(width)
    const locations = useTypedSelector(locationsSelector)
    const tagCount = TagsUtils.getVesselCountWithTagName(tagName, locations)
    return (
        <div style={{display: 'flex', gap: '5px'}}>
            <VesselTag tagName={tagName} showHover={true} maxWidth={maxWidth} key={idx} />
            <span>({tagCount})</span>
        </div>
    )
}
