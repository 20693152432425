import moment from 'moment'
import {Anomaly, FormattedAnomaly} from './types/formatted-anomaly'
import {
    FLEET_VIEW_IDENTIFIER,
    getLocationDescription,
    Location,
    LocationMap,
} from '../../../store/state/locations/state'
import {
    IsolatedAssetsType,
    IsolationStatusFormatted,
    IsolationStatusType,
    NetworkAssetState,
} from './types/isolated-assets-type'
import CIRCLE_CHECKED from '../../../@assets/icons/circle-checked.svg'
import CIRCLE_X from '../../../@assets/icons/circle-x.svg'
import CIRCLE_UNKNOWN from '../../../@assets/icons/circle-unknown.svg'
import CIRCLE_MONITORED from '../../../@assets/icons/circle-monitored.svg'
import {NetworkType} from './types/network-type'
import {NodeData} from '../../../values/nodes/NodeData'

function filterByLocation(anomalies: Anomaly[], activeLocation: Location): Anomaly[] {
    return anomalies.filter((anomaly) => isSelectedLocation(anomaly, activeLocation))
}
function isSelectedLocation(anomaly: Anomaly, activeLocation: Location): boolean {
    return (
        !activeLocation ||
        activeLocation.location === FLEET_VIEW_IDENTIFIER ||
        activeLocation.location === anomaly.location
    )
}
const getFormattedAnomalies = (
    anomalies: Anomaly[],
    locationMap: LocationMap,
    isolatedAssetsStatus: IsolatedAssetsType[] | undefined,
): FormattedAnomaly[] => {
    return anomalies.map((anomaly) => formatAnomaly(anomaly, locationMap, isolatedAssetsStatus))
}

const formatAnomaly = (
    anomaly: Anomaly,
    locationMap: LocationMap,
    isolatedAssetsStatus?: IsolatedAssetsType[] | undefined,
): FormattedAnomaly => ({
    occurred: moment(anomaly.firstSeen).fromNow(),
    timestamp: moment(anomaly.updatedTimestamp).format(),
    occurredLastSeen: getOccurredLastSeenDisplayValue(anomaly.lastSeen),
    lastSeenTimestamp: anomaly.lastSeen ? moment(anomaly.lastSeen).format() : '',
    ipAddress: anomaly.mainIpAddress,
    location: getLocationDescription(locationMap, anomaly.location),
    macAddress: anomaly.mainMacAddress ?? 'N/A',
    macDescription: anomaly.mainMacAddressVendor,
    identity: anomaly.identity,
    vlan: anomaly.vlan ?? 'N/A',
    network: formatNetworkLabel(anomaly.assetNetworkType),
    hostname: anomaly.name,
    isolationStatus: getIsolationStatusOfAsset(isolatedAssetsStatus, anomaly.identity),
    state: anomaly.state ?? NetworkAssetState.UNKNOWN,
})

export function setFormattedAnomalies(
    dataUnknownAssets: Anomaly[] | undefined,
    activeLocation: Location,
    locationMap: LocationMap,
    isolatedAssetsStatus: IsolatedAssetsType[] | undefined,
): FormattedAnomaly[] {
    if (dataUnknownAssets == undefined) {
        return new Array<FormattedAnomaly>()
    }
    return getFormattedAnomalies(
        filterByLocation(dataUnknownAssets, activeLocation),
        locationMap,
        isolatedAssetsStatus,
    )
}

export function getIsolationStatusOfAsset(
    isolatedAssetsStatus: IsolatedAssetsType[] | undefined,
    assetIdentity: string,
): IsolationStatusFormatted {
    if (!isolatedAssetsStatus) {
        return {
            isolationStatus: IsolationStatusType.UNDECIDED,
            updatedAt: '',
        }
    }
    const findStatusForAsset = isolatedAssetsStatus?.find(
        (isolatedAsset) => isolatedAsset.identity === assetIdentity,
    )
    if (!findStatusForAsset) {
        return {
            isolationStatus: IsolationStatusType.UNDECIDED,
            updatedAt: '',
        }
    }
    return {
        isolationStatus: findStatusForAsset?.isolationStatus,
        updatedAt: findStatusForAsset?.updatedAt,
    }
}

export function getOccurredLastSeenDisplayValue(
    occurredLastSeen: string | null | undefined,
): string {
    return !occurredLastSeen ? 'N/A' : moment(occurredLastSeen).fromNow()
}

export function formatNetworkLabel(networkLabel: string | undefined): NetworkType {
    switch (networkLabel) {
        case 'ot':
            return NetworkType.NetworkTypeOT
        case 'crew':
            return NetworkType.NetworkTypeCrew
        case 'business':
            return NetworkType.NetworkTypeBusiness
        default:
            return NetworkType.NetworkTypeOther
    }
}

export function getImageForNetworkAssetState(networkAssetState: NetworkAssetState): string {
    switch (networkAssetState) {
        case NetworkAssetState.TRUSTED:
            return CIRCLE_CHECKED
        case NetworkAssetState.MONITORED:
            return CIRCLE_MONITORED
        case NetworkAssetState.BLOCKED:
            return CIRCLE_X
        case NetworkAssetState.UNKNOWN:
            return CIRCLE_UNKNOWN
        default:
            return ''
    }
}

export function getAssetStatusForNoAc(
    acEnabled: boolean,
    networkAssetState: NetworkAssetState[] | undefined,
): NetworkAssetState[] | undefined {
    const hasAcValue = networkAssetState?.includes(NetworkAssetState.BLOCKED)
    if (acEnabled) {
        return networkAssetState
    }
    if (!networkAssetState) {
        return [NetworkAssetState.TRUSTED, NetworkAssetState.MONITORED, NetworkAssetState.UNKNOWN]
    }
    if (hasAcValue) {
        return networkAssetState.filter((value) => value !== NetworkAssetState.BLOCKED)
    }
    return networkAssetState
}

export function getMacAddressDisplayValue(macAddress: string | null | undefined): string {
    return !macAddress || macAddress.length == 0 ? 'N/A' : macAddress
}

export function getFirstDetectedDisplayValue(firstDetected: string | null | undefined): string {
    return !firstDetected ? 'Unknown' : moment(firstDetected).fromNow()
}
export function getHostnameDisplayValue(hostname: string | null | undefined): string {
    return !hostname || hostname.length == 0 ? 'Unknown' : hostname
}
export function getDisplayNameOfAsset(nodeData: NodeData | undefined): string | undefined {
    if (nodeData) {
        return nodeData.alias ?? nodeData.hostName ?? nodeData.ipAddress ?? nodeData.macAddress
    }
    return undefined
}
