import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {NoData} from '../no-data/no-data'
import {AssetStatusWidget} from './asset-status'
import {AssetTrendChart} from './asset-trend'
import {VesselPerformanceWidget} from './vessel-performance'
import {
    MetricsIssuesBehaviourRadarChart,
    MetricsIssuesMaintenanceProtectionRadarChart,
} from './metrics-radar-charts'
import {
    PageLayout,
    SectionContainer,
    SectionHeader,
    SectionWidgetsContainer,
    WidgetContainer,
    WidgetHeader,
} from './metrics-report.styled'
import {VesselTargetPerformanceWidget} from './vessel-target-performance'
import {TagsTable} from './table/tags-table'
import {IssueBreakdownByTagsTableHeader} from './types/metric-report-types'
import {TableHeaderCol} from './table/tags-table-header'
import {VesselHeatMapChart} from './vessel-heatmap'

export function MetricsReport() {
    const {reportLevelData} = usePagedMetricsBeta()

    if (!reportLevelData || !reportLevelData.summary) {
        return <NoData text="There are no reports matching your filtering criteria" />
    }

    const assetStatus = reportLevelData.summary.assetStatus
    const performance = reportLevelData.summary.performance
    const issuesRaised = reportLevelData.summary.issuesRaised
    const vesselPerformance = reportLevelData.summary.vesselPerformance
    const issueBreakdownByTags = reportLevelData.summary.issueBreakdownByTags
    const assetTrend = reportLevelData.summary.assetTrend
    const vesselPerformanceWithMetrics = reportLevelData.summary.vesselPerformanceWithMetrics

    const headers: TableHeaderCol[] = [
        {
            name: IssueBreakdownByTagsTableHeader.TAG_NAME,
            displayText: 'Tag Name',
            colType: 'string',
        },
        {
            name: IssueBreakdownByTagsTableHeader.TOTAL_ISSUE,
            displayText: 'Total Issue',
            colType: 'number',
        },
        {
            name: IssueBreakdownByTagsTableHeader.PROTECTION,
            displayText: 'Protection',
            colType: 'number',
        },
        {
            name: IssueBreakdownByTagsTableHeader.MAINTENANCE,
            displayText: 'Maintenance',
            colType: 'number',
        },
        {
            name: IssueBreakdownByTagsTableHeader.BEHAVIOUR,
            displayText: 'Behaviour',
            colType: 'number',
        },
    ]

    return (
        <PageLayout>
            <SectionContainer>
                <SectionHeader>Summary of fleet performance</SectionHeader>
                <SectionWidgetsContainer>
                    <WidgetContainer>
                        <WidgetHeader>ASSET STATUS BY FRAMEWORK CATEGORY</WidgetHeader>
                        {assetStatus && <AssetStatusWidget assetStatus={assetStatus} />}
                    </WidgetContainer>
                    <WidgetContainer>
                        <WidgetHeader>VESSEL TARGET PERFORMANCE BY FRAMEWORK CATEGORY</WidgetHeader>
                        {performance && <VesselTargetPerformanceWidget performance={performance} />}
                    </WidgetContainer>
                    <WidgetContainer>
                        <WidgetHeader>
                            BEST AND WORST VESSEL PERFORMANCE (Based on all metrics)
                        </WidgetHeader>
                        {vesselPerformance && (
                            <VesselPerformanceWidget vesselPerformance={vesselPerformance} />
                        )}
                    </WidgetContainer>
                    <WidgetContainer>
                        <WidgetHeader>ASSET TREND BY FRAMEWORK CATEGORY </WidgetHeader>
                        {assetTrend && <AssetTrendChart assetTrend={assetTrend} />}
                    </WidgetContainer>
                </SectionWidgetsContainer>
            </SectionContainer>
            <SectionContainer>
                <SectionHeader>Summary of issues and areas for improvement</SectionHeader>
                <SectionWidgetsContainer>
                    <WidgetContainer>
                        <WidgetHeader>ISSUES RAISED IN REPORT PERIOD (Crew Behaviour)</WidgetHeader>
                        {issuesRaised && (
                            <MetricsIssuesBehaviourRadarChart issuesRaised={issuesRaised} />
                        )}
                    </WidgetContainer>
                    <WidgetContainer>
                        <WidgetHeader>
                            ISSUES RAISED IN REPORT PERIOD (Maintenance/Protection)
                        </WidgetHeader>
                        {issuesRaised && (
                            <MetricsIssuesMaintenanceProtectionRadarChart
                                issuesRaised={issuesRaised}
                            />
                        )}
                    </WidgetContainer>
                    <WidgetContainer colSpan={2}>
                        <WidgetHeader>ISSUE TYPES DETECTED IN REPORT PERIOD</WidgetHeader>
                    </WidgetContainer>
                </SectionWidgetsContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionHeader>Vessel breakdown overview</SectionHeader>
                <SectionWidgetsContainer>
                    <WidgetContainer>
                        <WidgetHeader>ISSUE BREAKDOWN BY VESSEL AND METRICS</WidgetHeader>
                        {vesselPerformanceWithMetrics && (
                            <VesselHeatMapChart vesselPerformance={vesselPerformanceWithMetrics} />
                        )}
                    </WidgetContainer>

                    <WidgetContainer>
                        <WidgetHeader>ISSUE BREAKDOWN BY TAGS</WidgetHeader>
                        {issueBreakdownByTags && (
                            <TagsTable data={issueBreakdownByTags} headers={headers} />
                        )}
                    </WidgetContainer>
                </SectionWidgetsContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionHeader>Detailed view</SectionHeader>

                <SectionWidgetsContainer>
                    <WidgetContainer colSpan={2} bgColor="inherit"></WidgetContainer>
                </SectionWidgetsContainer>
            </SectionContainer>
        </PageLayout>
    )
}
