import {useTheme} from 'styled-components'
import {useEffect, useState} from 'react'
import * as Styled from './tags-table.styled'
import {TableHeader, TableHeaderCol} from './tags-table-header'
import {TagsTableContent} from './tags-table-body'
import {SortBy} from '../types/metric-report-types'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../../../../dashboard-v2/components/general/helpers'

/**
 * TagsTable and all its descendant, including data actions, is based on the
 * TagsTable component in `dashboard-v2` folder. Certain functionalities are
 * not kept to limit its scope.
 *
 * The first column of data array is preset to be tag name, so make sure that
 * it is so when the data is passed in.
 */
export function TagsTable({
    headers,
    data,
}: {
    headers: TableHeaderCol[]
    data: object[]
}): JSX.Element {
    const {width} = useDimensions()
    const theme = useTheme()
    const gridLayout = getGridLayout(width)
    const [sortBy, setSortBy] = useState<SortBy>({
        isAsc: true,
        colIdx: 0,
    })

    const [rows, setRows] = useState(initRows(data, headers))

    useEffect(() => {
        const idx = sortBy.colIdx
        if (headers[sortBy.colIdx].colType === 'number') {
            // Sort by number
            rows.sort((a, b) => {
                if (Number(a[idx]) && Number(b[idx])) {
                    return Number(a[idx]) - Number(b[idx])
                }
                return 0
            })
        } else {
            // Sort alphabetically
            rows.sort((a, b) => {
                return a[sortBy.colIdx].localeCompare(b[sortBy.colIdx])
            })
        }

        if (!sortBy.isAsc) {
            rows.reverse()
        }

        setRows([...rows])
    }, [sortBy])

    return (
        <Styled.Widget>
            <Styled.ContentArea gridLayout={gridLayout} tagWidget>
                <Styled.ContentContainerMiddle
                    width={width}
                    backgroundColor={theme.softwareInventory.dataArea.background}
                >
                    <TableHeader sortBy={sortBy} setSortBy={setSortBy} headers={headers} />
                </Styled.ContentContainerMiddle>
                <Styled.ContentContainerMiddle
                    width={width}
                    scrollable
                    backgroundColor={theme.softwareInventory.dataArea.background}
                >
                    <TagsTableContent rows={rows} />
                </Styled.ContentContainerMiddle>
            </Styled.ContentArea>
        </Styled.Widget>
    )
}

function initRows(data: object[], headers: TableHeaderCol[]): string[][] {
    let rows: string[][] = []

    data.forEach((d) => {
        let row: string[] = []
        for (let i = 0; i < headers.length; i++) {
            for (const [k, v] of Object.entries(d)) {
                if (headers[i].name === k) {
                    row = [...row, v.toString()]
                }
            }
        }
        rows = [...rows, row]
    })
    return rows
}
