import {VesselTags} from '../store/state/vessel-tags/state'
import {Location, LocationIdType} from '../store/state/locations/state'
import {GuidType} from '../values/generic-type-defintions'
import {TimeRange, TimeUnit} from '../values/TimeRange'

export class StringUtils {
    /**
     * Ensures value(s) strings and has a size after trim
     * @returns {boolean}
     * @param stringToCheck
     */
    public static validString(stringToCheck: unknown): stringToCheck is string {
        if (typeof stringToCheck !== 'string') {
            return false
        }
        return !(stringToCheck.length === 0 || stringToCheck.trim().length === 0)
    }

    public static capitaliseString(string: string): string {
        return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    }

    public static chop(string: string): string {
        return `${string.substring(0, string.length - 1)}`
    }

    public static isBetweenMinAndMaxInclusive(
        stringToCheck: string,
        min: number,
        max: number,
    ): boolean {
        return stringToCheck.length >= min && stringToCheck.length <= max
    }
}

export class BrowserUtils {
    public static isBrowserDarkMode(): boolean {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    }
}

export class TagsUtils {
    public static getVesselCountForTag(tag: VesselTags, locations: Location[]): number {
        return locations.filter((l) => l.tags?.some((t) => t.identity === tag.identity)).length
    }
    public static getVesselCountWithTagName(tagName: string, locations: Location[]): number {
        return locations.filter((l) => l.tags?.some((t) => t.name === tagName)).length
    }
}

export function getTagName(
    existingTags: VesselTags[],
    tagId: GuidType | undefined,
): string | undefined {
    return existingTags.find((tags) => tags.identity === tagId)?.name
}

export function populateLocations(
    locations: string | LocationIdType[] | undefined,
): Set<LocationIdType> | undefined {
    if (!locations || locations.length === 0 || locations === 'all-selected') {
        return undefined
    }
    if (locations === 'all-deselected') {
        return new Set<LocationIdType>()
    }
    return new Set<LocationIdType>(locations)
}

export function populateLocationsForFilterCriteria(
    locations: Set<LocationIdType> | undefined,
): LocationIdType[] | undefined {
    if (!locations) {
        return undefined
    }
    return Array.from(locations)
}

export function formattedLocationsBlockSet(locations: unknown): string[] | undefined {
    return !Array.isArray(locations) ? undefined : locations ?? undefined
}

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export function isEmailValid(email: string | undefined | null): boolean {
    if (!StringUtils.validString(email)) {
        return true
    }
    return email !== undefined && validEmailRegex.test(email)
}

export function getRelativeTimeRange(timeRange: TimeRange | null): string | null {
    return timeRange
        ? new TimeRange(timeRange.value, timeRange.unit, timeRange.name).toRelativeString()
        : null
}

export function getTimeRangeCriteria(relativeTimeRange: string | null): TimeRange | null {
    if (relativeTimeRange == null) {
        return null
    }

    const unit = relativeTimeRange.slice(-1) as TimeUnit
    const value = Number(StringUtils.chop(relativeTimeRange.substring(8)))
    const name = `${value} ${unit === 'h' ? 'Hours' : 'Days'}`

    return new TimeRange(value, unit, name)
}

export type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>
      }
    : T
export function getReportType(reportType: string | undefined): string {
    if (reportType == undefined) {
        return 'Unknown'
    }

    return reportType === '' ? 'All' : reportType
}
