import styled from 'styled-components'

export const SectionHeader = styled.div`
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-block: 20px;
`

export const PageLayout = styled.div`
    border: 5px solid white;
    overflow: auto;
`

export const SectionContainer = styled.div`
    padding-inline: 40px;
`

export const SectionWidgetsContainer = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 50px;
    grid-template-columns: 1fr 1fr;

    // 1 column on lower resolutions
    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`

export const WidgetContainer = styled.div<{colSpan?: number; bgColor?: string}>`
    background-color: ${(prop) => (prop.bgColor ? `${prop.bgColor}` : 'white')};
    padding-inline: 35px;
    padding-block: 15px;

    grid-column-end: ${(prop) => (prop.colSpan ? `span ${prop.colSpan}` : 'span 1')};

    @media (max-width: 1024px) {
        grid-column-end: span 1;
    }

    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const WidgetHeader = styled.div`
    margin: 0;
    font-size: 16px;
    font-weight: 600;
`

export const RadarChartDimensions = {
    height: 282,
    width: 532,
}
