export function MaintenanceIcon() {
    return (
        <svg
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="20.5965" cy="20" rx="20.5965" ry="20" fill="#6E19DB" fillOpacity="0.21" />
            <path
                d="M15.3196 12.9945C15.8896 12.791 16.508 12.7652 17.0931 12.9205C17.6781 13.0758 18.2022 13.405 18.5963 13.8645C18.9903 14.324 19.2357 14.8922 19.2999 15.4941C19.3642 16.0959 19.2444 16.7032 18.9563 17.2355L28.1093 26.3894L26.8999 27.5979L17.7478 18.4441C17.2154 18.7311 16.6085 18.8502 16.007 18.7855C15.4056 18.7208 14.8379 18.4754 14.3787 18.0817C13.9196 17.6879 13.5904 17.1643 13.4348 16.5798C13.2791 15.9952 13.3042 15.3773 13.5068 14.8073L15.417 16.7193C15.536 16.8384 15.6774 16.9328 15.8329 16.9973C15.9885 17.0618 16.1552 17.095 16.3235 17.095C16.4919 17.0951 16.6587 17.0619 16.8142 16.9975C16.9698 16.9331 17.1112 16.8387 17.2303 16.7197C17.3493 16.6007 17.4438 16.4593 17.5083 16.3038C17.5728 16.1483 17.606 15.9815 17.606 15.8132C17.6061 15.6448 17.5729 15.478 17.5085 15.3225C17.4441 15.1669 17.3497 15.0255 17.2307 14.9064L15.3196 12.9945ZM24.1803 14.6039L26.8999 13.0928L28.1093 14.3022L26.5982 17.0218L25.0871 17.3235L23.2743 19.1364L22.0649 17.9278L23.8786 16.115L24.1803 14.6039ZM18.4401 21.5543L19.6486 22.7629L15.1162 27.2953C14.9621 27.4499 14.7547 27.5396 14.5365 27.5461C14.3184 27.5527 14.106 27.4755 13.9429 27.3305C13.7798 27.1854 13.6784 26.9835 13.6595 26.7661C13.6405 26.5487 13.7054 26.3322 13.8409 26.1611L13.9068 26.0868L18.4401 21.5543Z"
                fill="#6E19DB"
            />
        </svg>
    )
}
