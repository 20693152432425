import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {smallFont} from '../../../../../theme/font-styles'

interface TableHeaderCellProps {
    gridColumn: number
    selectable?: boolean
}
export const TableHeaderCell = styled.div<TableHeaderCellProps>`
    grid-column: ${(props) => props.gridColumn};
    align-self: center;
    color: ${(props) => props.theme.colors.text.default};
    ${smallFont()}
    font-weight: ${(props) => props.theme.font.weight.bold};
    cursor: ${(props) => (props.selectable ? 'pointer' : 'inherit')};
`

interface IconStyledProps {
    arrowDec?: boolean
}

export const IconStyled = styled.div<IconStyledProps>`
    transition:
        transform 0.2s,
        color 0.2s;
    transition-timing-function: ease-in-out;
    transform: ${(props) => (props.arrowDec ? ' rotate(180deg) ' : '')};
`
interface TableHeaderTextProps {
    active?: boolean
    centered?: boolean
}
export const TableHeaderText = styled.div<TableHeaderTextProps>`
    display: flex;
    column-gap: 2px;
    align-items: center;
    color: ${(props) => (props.active ? props.theme.colors.link.primary : '')};
    ${(props) => (props.centered ? 'justify-content: center;' : '')}
`
interface Props {
    width: number
    showReducedGrid: boolean
}
export const TableHeaderGrid = styled.div<Props>`
    display: grid;
    grid-template-rows: 40px;
    column-gap: ${spacing(2)};
    padding: 0 ${spacing(1)};
    grid-template-columns: 0 repeat(5, 1fr);
`
