import {Colors} from '../../../theme/colours'
import {useContext, useEffect, useState} from 'react'
import {Dropdown} from './dropdown'
import * as Style from './asset-creation-form.styled'
import {useAssetOptions} from '../contexts/hooks/use-asset-options'
import {
    AssetOptionKey,
    LocationInventoryPageContext,
} from '../contexts/location-inventory-page-context'
import LoadingState from '../../../values/loading-state-enum'
import {CreateOTAssetReq} from '../../../store/state/ot-assets/state'
import {StringUtils} from '../../../utils/Utils'
import {validateOTAssetFormData} from '../validation/ot-validation'
import {Spinner} from '../../../components/loading/spinner'
import {AddButton} from './add-button'
import ActionType from '../contexts/state/asset-form/action-type'
import {FormError} from './form-error'
import {getDropdownOptions} from './asset-creation-form-helper'
import {
    setFormDropdownValue,
    setFormDropdownValues,
    setFormStringValue,
} from '../contexts/state/asset-form/action-creators'
import {MultiselectCheckbox} from './multiselect-checkbox'
import {RemoveButton} from '../../../components/remove-button/remove-button'
import {currentUserSelector} from '../../../store/state/current-user/selectors'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {StrictMode} from '../../../store/state/users/state'
import {useToast} from '../../../components/toast/toast'
import {AssetStatusDropdownOptions} from './asset-status'

type AssetCreationFormProps = {
    onDismiss: () => void
    onSubmit: (req: CreateOTAssetReq) => void
    onClickAddAssetOption: (openedModal: boolean) => void
    assetOptionKeySetter: (assetOptionKey: AssetOptionKey) => void
    mode: 'create' | 'edit'
    closeForm?: () => void
}

const mandatoryFields = ['name', 'assetTypeID', 'systemID', 'zoneID']

const isAssetValid = (asset: CreateOTAssetReq): boolean => {
    const missed = mandatoryFields.filter((key) => asset[key] === '')

    return missed.length === 0
}

export function FormSubmitError({error}: {error: string}) {
    // Don't render if there is no error
    if (!StringUtils.validString(error)) {
        return null
    }

    return <Style.FormErrorMessage>{error}</Style.FormErrorMessage>
}

export function AssetCreationForm({
    onDismiss,
    onClickAddAssetOption,
    assetOptionKeySetter,
    closeForm,
    onSubmit,
    mode = 'create',
}: AssetCreationFormProps): JSX.Element {
    const {assetForm} = useContext(LocationInventoryPageContext)

    const {strictMode} = useTypedSelector(currentUserSelector)
    const isNotStrictMode = strictMode !== StrictMode.Strict

    const {
        name,
        assetTypeID: assetType,
        systemID: system,
        zoneID: zone,
        vendor,
        status,
        model,
        description,
        physicalInterfaceIDs: physicalInterfaces,
        softwareID: softwareType,
        softwareVersion,
        communicationProtocolID: communicationProtocol,
        customFields,
    } = assetForm.state.formData

    const formError = assetForm.state.formFieldError

    const {dispatch} = assetForm

    const {assetFormOptions} = useAssetOptions()
    const {formOptions: assetOptions, formOptionsLoadingState: loadingState} =
        assetFormOptions.state
    const submitionState = assetForm.state.formSubmitLoadingState

    const [isCompleted, setCompleted] = useState(false)
    const formSubmitError = assetForm.state.formSubmitError
    const canSave = isCompleted && submitionState !== LoadingState.RequestingData
    const onClickAddAssetOptionBtnHandler = (assetOptionKey: AssetOptionKey) => {
        onClickAddAssetOption(true)
        assetOptionKeySetter(assetOptionKey)
    }

    let physicalInterfaceIDs: string[] = []
    if (physicalInterfaces.length > 0) {
        physicalInterfaceIDs = physicalInterfaces.map((x) => x.value)
    }

    const newOTAsset: CreateOTAssetReq = {
        name,
        assetTypeID: assetType.value,
        systemID: system.value,
        zoneID: zone.value,
        vendor,
        status: status.value,
        model,
        description,
        physicalInterfaceIDs: physicalInterfaceIDs,
        softwareID: softwareType.value,
        softwareVersion,
        communicationProtocolID: communicationProtocol.value,
        customFields,
    }

    if (isAssetValid(newOTAsset) !== isCompleted) {
        setCompleted(!isCompleted)
    }

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()

        const {success, errors} = validateOTAssetFormData(newOTAsset)

        // Either reset error or display new ones
        dispatch?.({type: ActionType.SET_ASSET_FORM_ERRORS, payload: errors})
        // Reset form submit error
        dispatch?.({type: ActionType.SET_ASSET_FORM_SUBMIT_ERROR, payload: {error: ''}})

        if (!success) {
            return
        }

        onSubmit(newOTAsset)
    }

    function _closeForm() {
        // If a custom close function is provided, use it
        // This is to provided custom popup before closing
        if (closeForm) {
            closeForm()
            return
        }
        // Reset form state
        dispatch?.({type: ActionType.RESET_ASSET_CREATION_FORM})

        onDismiss()
    }

    const shouldRenderFirstRow = mode === 'create'

    const disableAddCustomFieldButton = customFields.length >= 10

    const toast = useToast()

    useEffect(() => {
        if (customFields.length < 10 && communicationProtocol.label === 'Ethernet/IP') {
            toast('Please create Additional Field to add IP Address', {position: 'bottom-right'})
        }
    }, [communicationProtocol])

    return (
        <>
            {shouldRenderFirstRow && (
                <Style.Row>
                    <Style.Cell>
                        <Style.FormInputContent>
                            <Style.TextInput
                                value={name}
                                placeholder="Enter asset name *"
                                onChange={(event) =>
                                    dispatch?.(setFormStringValue('name', event.target.value))
                                }
                                form="asset-creation-form"
                            />
                            <FormError error={formError.name} />
                        </Style.FormInputContent>
                    </Style.Cell>
                    <Style.Cell>
                        <Style.FormInputContent>
                            <Style.DropdownRow>
                                <Dropdown
                                    options={getDropdownOptions(
                                        assetOptions.assetTypes,
                                        loadingState,
                                    )}
                                    selectedOption={assetType}
                                    onChage={(option) =>
                                        dispatch?.(setFormDropdownValue('assetTypeID', option))
                                    }
                                    placeholder="Model/Type *"
                                    required
                                />
                                {isNotStrictMode && (
                                    <AddButton
                                        disabled={false}
                                        width={15}
                                        height={15}
                                        onClick={() =>
                                            onClickAddAssetOptionBtnHandler('assetTypes')
                                        }
                                    ></AddButton>
                                )}
                            </Style.DropdownRow>
                            <FormError error={formError.assetTypeID} />
                        </Style.FormInputContent>
                    </Style.Cell>
                    <Style.Cell>
                        <Style.FormInputContent>
                            <Style.DropdownRow>
                                <Dropdown
                                    options={getDropdownOptions(assetOptions.systems, loadingState)}
                                    selectedOption={system}
                                    onChage={(option) =>
                                        dispatch?.(setFormDropdownValue('systemID', option))
                                    }
                                    placeholder="Vessel system *"
                                    required
                                />
                                {isNotStrictMode && (
                                    <AddButton
                                        disabled={false}
                                        width={15}
                                        height={15}
                                        onClick={() => onClickAddAssetOptionBtnHandler('systems')}
                                    ></AddButton>
                                )}
                            </Style.DropdownRow>
                            <FormError error={formError.systemID} />
                        </Style.FormInputContent>
                    </Style.Cell>
                    <Style.Cell>
                        <Style.FormInputContent>
                            <Style.DropdownRow>
                                <Dropdown
                                    options={getDropdownOptions(assetOptions.zones, loadingState)}
                                    selectedOption={zone}
                                    onChage={(option) =>
                                        dispatch?.(setFormDropdownValue('zoneID', option))
                                    }
                                    placeholder="Zone *"
                                    required
                                />
                                {isNotStrictMode && (
                                    <AddButton
                                        disabled={false}
                                        width={15}
                                        height={15}
                                        onClick={() => onClickAddAssetOptionBtnHandler('zones')}
                                    ></AddButton>
                                )}
                            </Style.DropdownRow>
                            <FormError error={formError.zoneID} />
                        </Style.FormInputContent>
                    </Style.Cell>
                    <Style.Cell>{isCompleted ? 'Completed' : ''}</Style.Cell>
                    <Style.Cell>
                        <Style.FormInputContent>
                            <Style.DropdownRow>
                                <Dropdown
                                    options={AssetStatusDropdownOptions}
                                    selectedOption={status}
                                    onChage={(option) =>
                                        dispatch?.(setFormDropdownValue('status', option))
                                    }
                                    placeholder="Lifecycle status *"
                                    required
                                />
                            </Style.DropdownRow>
                        </Style.FormInputContent>
                    </Style.Cell>
                    <Style.Cell></Style.Cell>
                </Style.Row>
            )}
            <Style.Row>
                <Style.Cell colSpan={7}>
                    <form id="asset-creation-form" onSubmit={handleFormSubmit}>
                        <Style.FormFields>
                            <Style.Field>
                                <Style.FormLabel>Manufacturer/Brand:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.TextInput
                                        value={vendor}
                                        placeholder="e.g. Furuno"
                                        onChange={(event) =>
                                            dispatch?.(
                                                setFormStringValue('vendor', event.target.value),
                                            )
                                        }
                                    />
                                    <FormError error={formError.vendor} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>Physical interfaces:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.DropdownRow>
                                        <MultiselectCheckbox
                                            options={getDropdownOptions(
                                                assetOptions.physicalInterfaces,
                                                loadingState,
                                            )}
                                            selectedOptions={physicalInterfaces}
                                            onChange={(option) =>
                                                dispatch?.(
                                                    setFormDropdownValues(
                                                        'physicalInterfaceIDs',
                                                        option,
                                                    ),
                                                )
                                            }
                                            placeholder="e.g. network, serial"
                                        />
                                        <Style.Hider>
                                            {isNotStrictMode && (
                                                <AddButton
                                                    disabled={true}
                                                    width={15}
                                                    height={15}
                                                ></AddButton>
                                            )}
                                        </Style.Hider>
                                    </Style.DropdownRow>
                                    <FormError error={formError.physicalInterfaceIDs} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>
                                    Version and patch version of system software:
                                </Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.TextInput
                                        value={softwareVersion}
                                        placeholder="Version number"
                                        onChange={(event) =>
                                            dispatch?.(
                                                setFormStringValue(
                                                    'softwareVersion',
                                                    event.target.value,
                                                ),
                                            )
                                        }
                                    />
                                    <FormError error={formError.softwareVersion} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>Model:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.TextInput
                                        value={model}
                                        placeholder="e.g. ECDIS"
                                        onChange={(event) =>
                                            dispatch?.(
                                                setFormStringValue('model', event.target.value),
                                            )
                                        }
                                    />
                                    <FormError error={formError.model} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>Name/Type of system software:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.DropdownRow>
                                        <Dropdown
                                            options={getDropdownOptions(
                                                assetOptions.softwares,
                                                loadingState,
                                            )}
                                            selectedOption={softwareType}
                                            onChage={(option) =>
                                                dispatch?.(
                                                    setFormDropdownValue('softwareID', option),
                                                )
                                            }
                                            placeholder="e.g. operating system"
                                            groupOptions={true}
                                        />
                                        {isNotStrictMode && (
                                            <AddButton
                                                disabled={false}
                                                width={15}
                                                height={15}
                                                onClick={() =>
                                                    onClickAddAssetOptionBtnHandler('softwares')
                                                }
                                            ></AddButton>
                                        )}
                                    </Style.DropdownRow>
                                    <FormError error={formError.softwareID} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>Supported communication protocol:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Dropdown
                                        options={getDropdownOptions(
                                            assetOptions.communicationProtocols,
                                            loadingState,
                                        )}
                                        selectedOption={communicationProtocol}
                                        onChage={(option) =>
                                            dispatch?.(
                                                setFormDropdownValue(
                                                    'communicationProtocolID',
                                                    option,
                                                ),
                                            )
                                        }
                                        placeholder="Protocols"
                                    />
                                    <FormError error={formError.communicationProtocolID} />
                                </Style.FormInputContent>
                            </Style.Field>
                            <Style.Field>
                                <Style.FormLabel>Description:</Style.FormLabel>
                                <Style.FormInputContent>
                                    <Style.TextInput
                                        value={description}
                                        placeholder="e.g. View Navigation Charts"
                                        onChange={(event) =>
                                            dispatch?.(
                                                setFormStringValue(
                                                    'description',
                                                    event.target.value,
                                                ),
                                            )
                                        }
                                    />
                                    <FormError error={formError.description} />
                                </Style.FormInputContent>
                            </Style.Field>
                        </Style.FormFields>
                        <Style.HR></Style.HR>
                        <Style.AdditionalFieldTop>
                            <h4>Additional Field</h4>
                            <AddButton
                                onClick={() => dispatch?.({type: ActionType.ADD_CUSTOM_FIELD})}
                                disabled={disableAddCustomFieldButton}
                            >
                                Add Additional Field
                            </AddButton>
                        </Style.AdditionalFieldTop>

                        <Style.AdditionalFieldContent>
                            {customFields.map((field, index) => (
                                <Style.AdditionalFieldRow key={index}>
                                    <Style.FormInputContent>
                                        <Style.AdditionalFieldTextInput
                                            value={field.name}
                                            placeholder="Name of custom field"
                                            onChange={(event) =>
                                                dispatch?.({
                                                    type: ActionType.SET_FORM_ADDITIONAL_FIELD_VALUE,
                                                    payload: {
                                                        index: index,
                                                        field: 'name',
                                                        value: event.target.value,
                                                    },
                                                })
                                            }
                                        />
                                        <FormError error={formError.customFields[index]?.name} />
                                    </Style.FormInputContent>
                                    <Style.FormInputContent>
                                        <Style.AdditionalFieldTextInput
                                            value={field.value}
                                            placeholder="Value of custom field"
                                            onChange={(event) =>
                                                dispatch?.({
                                                    type: ActionType.SET_FORM_ADDITIONAL_FIELD_VALUE,
                                                    payload: {
                                                        index: index,
                                                        field: 'value',
                                                        value: event.target.value,
                                                    },
                                                })
                                            }
                                        />

                                        <FormError error={formError.customFields[index]?.value} />
                                    </Style.FormInputContent>
                                    <RemoveButton
                                        id={`remove-additional-field-${index}`}
                                        onClick={() =>
                                            dispatch?.({
                                                type: ActionType.REMOVE_CUSTOM_FIELD,
                                                payload: {index},
                                            })
                                        }
                                    />
                                </Style.AdditionalFieldRow>
                            ))}
                        </Style.AdditionalFieldContent>
                        <Style.FormRowActions>
                            <FormSubmitError error={formSubmitError} />
                            <Style.FormRowActionsButtons>
                                <Style.Button
                                    color={Colors.blue2}
                                    onClick={_closeForm}
                                    primary={false}
                                    type="button"
                                >
                                    Discard
                                </Style.Button>
                                <Style.Button
                                    color={canSave ? Colors.blue2 : '#A7B0BE'}
                                    primary={canSave}
                                    type="submit"
                                    disabled={!canSave}
                                >
                                    {submitionState === LoadingState.RequestingData && (
                                        <Spinner color="#A7B0BE" />
                                    )}
                                    Save
                                </Style.Button>
                            </Style.FormRowActionsButtons>
                        </Style.FormRowActions>
                    </form>
                </Style.Cell>
            </Style.Row>
        </>
    )
}
