import {useDispatch} from 'react-redux'
import {useModalEditAsset} from '../../../../contexts/edit-asset/use-modal-edit-asset'
import * as Styled from './submit-area.styled'
import {logChangeAssetValues} from '../../../../../../store/state/audit-log/action-creators'

export function AddButton(): JSX.Element | null {
    const {
        saveEditAssetChanges,
        newAllias,
        currentAllias,
        currentAssetValue,
        newAssetValue,
        assetId,
    } = useModalEditAsset()
    const dispatch = useDispatch()

    const blocked =
        (currentAllias?.length === 0 && newAllias.length === 0) ||
        (newAssetValue === currentAssetValue && currentAllias === newAllias)
    function onClick() {
        saveEditAssetChanges(dispatch)
        let auditAssetValue = undefined
        if (newAssetValue !== currentAssetValue) {
            auditAssetValue = newAssetValue
        }
        let auditAssetHostname = null
        if (currentAllias !== newAllias) {
            auditAssetHostname = newAllias
        }
        dispatch(logChangeAssetValues(auditAssetValue, auditAssetHostname, assetId))
    }

    return (
        <Styled.ButtonStyled disabled={blocked} id="add-button" onClick={onClick}>
            Save
        </Styled.ButtonStyled>
    )
}
