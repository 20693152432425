import createCachedSelector from 're-reselect'
import {nodesForAgentLocationsSelector} from '../../../../../../../../store/state/nodes/selectors'
import {sfmScoresSelector} from '../../../../../../../../store/state/sfm-scores/selectors'
import {SFMScoreMap} from '../../../../../../../../store/state/sfm-scores/state'
import AppState from '../../../../../../../../store/types/app-state'
import {GuidType} from '../../../../../../../../values/generic-type-defintions'
import {NodeData} from '../../../../../../../../values/nodes/NodeData'

interface SortableRecord {
    assetId: GuidType
    assetValueLevel: string
    assetScore: number
    assetName: string
}

function populateSortableRecord(node: NodeData, sfmScores: SFMScoreMap): SortableRecord {
    return {
        assetId: node.node,
        assetName: node.alias,
        assetScore: Math.round(sfmScores?.get(node.node) || 0),
        assetValueLevel: node.value.toLowerCase(),
    }
}

const sortOrder = ['high', 'medium', 'low']

function compareRecords(a: SortableRecord, b: SortableRecord): number {
    return (
        sortOrder.indexOf(a.assetValueLevel) - sortOrder.indexOf(b.assetValueLevel) ||
        b.assetScore - a.assetScore ||
        a.assetName.localeCompare(b.assetName)
    )
}

export const assetListCachedReselector = createCachedSelector(
    [
        nodesForAgentLocationsSelector,
        sfmScoresSelector,
        (_state: AppState, locationId: GuidType) => locationId,
    ],
    (nodesForLocations, scores, locationId) => {
        const data = nodesForLocations.get(locationId)
        if (!data) {
            return new Array<GuidType>()
        }

        return data
            .map((node) => populateSortableRecord(node, scores))
            .sort(compareRecords)
            .map((record) => record.assetId)
    },
)((_state, locationId) => locationId)
