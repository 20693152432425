import styled from 'styled-components'
import {spacing} from '../../../../../theme/spacing'
import {mediumSmallFont} from '../../../../../theme/font-styles'

interface TableDataRowProps {
    gridRow: number
    span?: number
}

export const TableDataRow = styled.div<TableDataRowProps>`
    grid-row: ${(props) => `${props.gridRow} / span ${props.span ?? 1}`};
    grid-column-start: 1;
    grid-column-end: -1;
    color: ${(props) => props.theme.softwareInventory.dataRow.text};
    background-color: ${(props) => props.theme.softwareInventory.dataRow.background};
    min-height: 40px;
    box-shadow: 1px 1px 6px -2px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
`

interface Props {
    width: number
    showReducedGrid: boolean
}

export const TableDataGrid = styled.div<Props>`
    display: grid;
    grid-template-columns: 0 repeat(5, 1fr);
    row-gap: ${spacing(1)};
    column-gap: ${spacing(2)};
    padding: ${spacing(1)};
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    max-height: 400px;
`

interface TableDataCellProps {
    gridRow: number
    gridColumn: number
    centered?: boolean
}

export const TableDataCell = styled.div<TableDataCellProps>`
    grid-row: ${(props) => props.gridRow};
    grid-column: ${(props) => props.gridColumn};
    margin-left: 0;
    white-space: nowrap;
    align-self: center;
    text-align: ${(props) => (props.centered ? 'center' : 'inherit')};
    color: ${(props) => props.theme.colors.text.default};
    ${mediumSmallFont()};
    font-weight: ${(props) => props.theme.font.weight.semibold};
`
