import ActionType from './action-type'
import * as Actions from './actions'
import {Api, QuerySpecification} from '../../../api/Api'
import {NodeData} from '../../../values/nodes/NodeData'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import * as uuid from 'uuid'
import {QueryCancellation} from '../../../websocket/Queries'
import {LatestNodeDetails} from './state'

const NODES_REST_URL = '/api/v1/nodes'
const NODES_AGENT_REST_URL = '/api/v1/nodes?hasAgent=true'

const QUERY_PATH = 'newAssetEvent'
const QUERY_SCHEDULE = '1m'

function newAssetDiscoveredQuery(): QuerySpecification {
    return {
        path: QUERY_PATH,
        localId: `${QUERY_PATH}-${uuid.v4()}`,
        schedule: QUERY_SCHEDULE,
        params: {
            active: true,
        },
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFormatedNodeDetails(items: any): LatestNodeDetails {
    const keys = Object.keys(items)
    return {
        id: keys[0],
        timeStamp: items[keys[0]],
    }
}
export function registerNewAssetDiscoveredQuery(): ThunkAction<
    void,
    AppState,
    Api,
    Actions.Action
> {
    return (dispatch, getState, api: Api): QueryCancellation => {
        const query: QuerySpecification = newAssetDiscoveredQuery()
        api.newQuery(query, (data) => {
            const latestStateNodeTimestamp = getState().nodes.latestNodeDetails?.timeStamp

            if (
                !latestStateNodeTimestamp ||
                getFormatedNodeDetails(data.items).timeStamp > latestStateNodeTimestamp
            ) {
                dispatch(fetchNodes())
            }
        })

        return () => {
            api.cancelQuery({localId: query.localId})
        }
    }
}

export function fetchNodes(): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch, _, api: Api): void => {
        dispatch(requestNodesAction())
        api.getAuth(NODES_AGENT_REST_URL, (data) => {
            dispatch(receiveNodesForAgentAction(data as NodeData[]))
        })
        api.getAuth(NODES_REST_URL, (data) => {
            dispatch(receiveNodesAction(data as NodeData[]))
        })
    }
}

function requestNodesAction(): Actions.RequestNodesAction {
    return {
        type: ActionType.REQUEST_NODES,
    }
}

function receiveNodesAction(nodes: NodeData[]): Actions.ReceiveNodesAction {
    return {
        type: ActionType.RECEIVE_NODES,
        payload: nodes,
    }
}
function receiveNodesForAgentAction(nodes: NodeData[]): Actions.ReceiveNodesAction {
    return {
        type: ActionType.RECEIVE_NODES_FOR_AGENT,
        payload: nodes,
    }
}
