import {
    CrewBehaviourColor,
    SummaryDisplayNumber,
    SummaryCategory,
    SummaryWidgetLayout,
    MaintenanceProcessColor,
    ProtectiveControlColor,
    SummaryText,
    SummaryDescription,
    SummaryMarginedText,
    SummaryBadgeGreen,
} from './common.styled'
import {BehaviourIcon} from './icons/behaviours'
import {MaintenanceIcon} from './icons/maintenance'
import {ProtectionIcon} from './icons/protection'
import {Performance} from './types/metric-report-types'

interface Props {
    performance: Performance
}

export function VesselTargetPerformanceWidget(props: Props) {
    const {protection, maintenance, behaviour} = props.performance
    return (
        <SummaryWidgetLayout>
            <SummaryCategory>
                <ProtectionIcon />
                <SummaryDescription>Protective Control</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={ProtectiveControlColor}>
                        {protection.aboveTarget}
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>
                        OF {protection.aboveTarget + protection.belowTarget} VESSEL(S)
                    </SummaryMarginedText>
                    <SummaryBadgeGreen>Above Target</SummaryBadgeGreen>
                </SummaryText>
            </SummaryCategory>

            <SummaryCategory>
                <MaintenanceIcon />
                <SummaryDescription>Maintenance Process</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={MaintenanceProcessColor}>
                        {maintenance.aboveTarget}
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>
                        OF {maintenance.aboveTarget + maintenance.belowTarget} VESSEL(S)
                    </SummaryMarginedText>
                    <SummaryBadgeGreen>Above Target</SummaryBadgeGreen>
                </SummaryText>
            </SummaryCategory>
            <SummaryCategory>
                <BehaviourIcon />
                <SummaryDescription>Crew Behaviour</SummaryDescription>
                <SummaryText>
                    <SummaryDisplayNumber color={CrewBehaviourColor}>
                        {behaviour.aboveTarget}
                    </SummaryDisplayNumber>
                    <SummaryMarginedText>
                        OF {behaviour.aboveTarget + behaviour.belowTarget} VESSEL(S)
                    </SummaryMarginedText>
                    <SummaryBadgeGreen>Above Target</SummaryBadgeGreen>
                </SummaryText>
            </SummaryCategory>
        </SummaryWidgetLayout>
    )
}
