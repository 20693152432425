import React from 'react'
import * as Styled from './tags-table-header.styled'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {SortBy} from '../types/metric-report-types'

interface TableHeaderProps {
    sortBy: SortBy
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>
    headers: TableHeaderCol[]
}

type ColType = 'string' | 'number'

export interface TableHeaderCol {
    name: string
    displayText: string
    colType: ColType
}

export function TableHeader({sortBy, setSortBy, headers}: TableHeaderProps): JSX.Element {
    const {width} = useDimensions()
    return (
        <Styled.TableHeaderGrid width={width} showReducedGrid={false}>
            {headers.map((h, i) => (
                <SortableColumn
                    gridColumn={i + 2}
                    header={h}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    colIdx={i}
                    key={i}
                />
            ))}
        </Styled.TableHeaderGrid>
    )
}

interface SortableColumnProps {
    gridColumn: number
    header: TableHeaderCol
    sortBy: SortBy
    setSortBy: React.Dispatch<React.SetStateAction<SortBy>>
    colIdx: number
}

function SortableColumn({
    gridColumn,
    header,
    sortBy,
    setSortBy,
    colIdx,
}: SortableColumnProps): JSX.Element {
    const getNewSortByDirection = (selectedColIdx: number, currentSortColumn: SortBy): boolean => {
        if (selectedColIdx !== currentSortColumn.colIdx) {
            return false
        }
        return !currentSortColumn.isAsc
    }

    function onSort(colIdx: number) {
        const newSortBy: SortBy = {
            isAsc: getNewSortByDirection(colIdx, sortBy),
            colIdx,
        }
        setSortBy(newSortBy)
    }

    return (
        <Styled.TableHeaderCell id={`sortable_${header.name}`} gridColumn={gridColumn} selectable>
            <Styled.TableHeaderText
                onClick={() => onSort(colIdx)}
                active={sortBy.colIdx === colIdx}
                centered={gridColumn === 2 ? false : true}
            >
                <div style={{textAlign: 'center'}}>{header.displayText}</div>
                <Styled.IconStyled arrowDec={sortBy.colIdx === colIdx && !sortBy.isAsc}>
                    {<Icon width={12} height={17} glyph="Arrow" />}
                </Styled.IconStyled>
            </Styled.TableHeaderText>
        </Styled.TableHeaderCell>
    )
}
