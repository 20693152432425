import styled from 'styled-components'
import {smallFont} from '../../../../theme/font-styles'

export const ScoreBarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
`

export const DescriptionWrapper = styled.div`
    flex: 1 1;
    overflow-x: auto;
`

export const Description = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.bold};
`

export const ScoreBar = styled.div`
    height: 12px;
    flex: 0 1 200px;
    background-color: #e2e5e9;
    border-radius: 5px;
    position: relative;
`

export const ScoreBarSpan = styled.span`
    font-size: 14px;
    flex: 0 1 220px;
    text-align: right;
`

interface ScoreBarNoIssueProps {
    score: number
}

export const ScoreBarNoIssue = styled.div<ScoreBarNoIssueProps>`
    height: 12px;
    width: ${(props) => (getScoreDisplay(props.score) / 100) * 200}px;
    background-color: #6d7c92;
    border-radius: 5px 0 0 5px;
`
function getScoreDisplay(score: number): number {
    return score === 0 ? 2 : score
}

export const TippyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    position: absolute;
    bottom: 5px;
    left: 20px;
    gap: 5px;
    border: 1px solid ${(props) => props.theme.colors.border.lowContrast};
    ${smallFont()}
    padding: 5px;
    border-radius: 2px;
    z-index: 100;
`
