import {isEqual} from 'lodash'

import {MetricType} from '../contexts/types/metrics-response'
import PROTECTION_ICON from '../../../@assets/icons/metric-protection-type.svg'
import MAINTENANCE_ICON from '../../../@assets/icons/metric-maintenance-type.svg'
import BEHAVIOUR_ICON from '../../../@assets/icons/metric-behaviour-type.svg'
import {
    AnalysisType,
    MetricsBetaFilter,
    MetricsBetaFilterReduxState,
    TimestampFilterType,
    ViewScreenLevelType,
} from '../../../store/state/metrics-filter-beta/state'
import {TriStateButtonState} from '../../../components/form-elements/tri-state-button/tri-state-button-state'
import {LocationIdType} from '../../../store/state/locations/state'
import {NodeValue, NodeValues} from '../../../values/nodes/NodeData'
import moment from 'moment'
import {DEFAULT_DATE_FORMAT} from '../../../helpers/consts'
export class FormattedNewFilterModel {
    public readonly locations: Set<LocationIdType> | LocationIdType[] | undefined
    public readonly metricTypes: MetricType[] | undefined
    public readonly selectedAnalysisPeriod: TimestampFilterType
    public readonly searchVesselTagTerm: string[]
    public readonly searchVesselNameTerm: string
    public readonly selectedViewScreenType: ViewScreenLevelType
    public readonly analysisTypes: AnalysisType
    public readonly assetValues: NodeValue[] | undefined

    public constructor(newFilter: MetricsBetaFilterReduxState | MetricsBetaFilter) {
        this.locations = newFilter.locations
        this.metricTypes = newFilter.metricTypes
        this.selectedAnalysisPeriod = newFilter.selectedAnalysisPeriod
        this.searchVesselTagTerm = newFilter.searchVesselTagTerm
        this.searchVesselNameTerm = newFilter.searchVesselNameTerm
        this.selectedViewScreenType = newFilter.selectedViewScreenType
        this.analysisTypes = newFilter.analysisTypes
        this.assetValues = newFilter.assetValues
    }
}

export function compareFilters(
    currentFilter: MetricsBetaFilter,
    newFilter: MetricsBetaFilterReduxState,
): boolean {
    const formattedNewFilter = new FormattedNewFilterModel(newFilter)
    const formattedCurrentFilter = new FormattedNewFilterModel(currentFilter)
    return !isEqual(formattedNewFilter, formattedCurrentFilter)
}
export function findMetricCategory(metricType: MetricType | string): string {
    if (
        metricType === 'malwareDetection' ||
        metricType === 'osSupported' ||
        metricType === 'priviligedAccounts' ||
        metricType === 'removableControls' ||
        metricType === 'securityTools' ||
        metricType === 'protection' ||
        metricType === 'accessControls'
    ) {
        return 'protection'
    } else if (
        metricType === 'malwareDefinition' ||
        metricType === 'osSecurityPatches' ||
        metricType === 'assetOnline' ||
        metricType === 'maintenance'
    ) {
        return 'maintenance'
    } else if (
        metricType === 'usbStorage' ||
        metricType === 'mobileDevices' ||
        metricType === 'hotspotMode' ||
        metricType === 'wirelessConnection' ||
        metricType === 'adminLogons' ||
        metricType === 'portableSoftware' ||
        metricType === 'behaviour'
    ) {
        return 'behaviour'
    }
    return metricType
}
export function getMetricTypeIcon(metricType: string): string {
    switch (metricType) {
        case 'protection':
            return PROTECTION_ICON
        case 'maintenance':
            return MAINTENANCE_ICON
        case 'behaviour':
            return BEHAVIOUR_ICON
        default:
            return ''
    }
}
export function determineViewAllState(
    allMainMetricsType: MetricType[],
    selected: MetricType[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    if (selected.length === 0 || allMainMetricsType.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    } else {
        return allMainMetricsType.every((item) => selected.includes(item))
            ? TriStateButtonState.FULLY_SELECTED
            : allMainMetricsType.some((item) => selected.includes(item))
              ? TriStateButtonState.PARTIAL_SELECTION
              : TriStateButtonState.NOT_SELECTED
    }
}

export function getAnalysisPeriodDisplayName(types: TimestampFilterType): string {
    switch (types) {
        case TimestampFilterType.LAST_24_H:
            return 'Last 24 hours'
        case TimestampFilterType.LAST_7_DAYS:
            return 'Last 7 days'
        case TimestampFilterType.LAST_30_DAYS:
            return 'Last 30 days'
        case TimestampFilterType.ONE_FORTNIGHT:
            return 'Last fortnight'
        case TimestampFilterType.ONE_QUARTER:
            return 'Last quarter'
        case TimestampFilterType.ONE_YEAR:
            return 'Last year'
        case TimestampFilterType.LATEST:
            return 'Latest'
        case TimestampFilterType.LAST_2_H:
            return 'Last 2 hours'
        default:
            return 'Last 30 days'
    }
}

export function getAnalysisTypeDisplayName(types: AnalysisType): string {
    switch (types) {
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return 'Worst Performing Vessels'
        case AnalysisType.TRENDS:
            return 'Trends'
        case AnalysisType.BENCHMARK:
            return 'Benchmark'
        case AnalysisType.TARGET:
            return 'Target'
        case AnalysisType.SCORECARD:
        default:
            return 'Scorecard'
    }
}

export function findIfDefaultFilterIsChanged(
    currentFilter: MetricsBetaFilter,
    locations: Set<LocationIdType> | undefined,
    metricTypes: MetricType[] | undefined,
    selectedAnalysisPeriod: TimestampFilterType,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    selectedViewScreenType: ViewScreenLevelType,
    analysisTypes: AnalysisType,
    assetValues: NodeValue[] | undefined,
): boolean {
    return (
        isEqual(currentFilter.locations, locations) &&
        isEqual(currentFilter.metricTypes, metricTypes) &&
        isEqual(currentFilter.selectedAnalysisPeriod, selectedAnalysisPeriod) &&
        isEqual(currentFilter.searchVesselTagTerm, searchVesselTagTerm) &&
        isEqual(currentFilter.searchVesselNameTerm, searchVesselNameTerm) &&
        isEqual(currentFilter.selectedViewScreenType, selectedViewScreenType) &&
        isEqual(currentFilter.analysisTypes, analysisTypes) &&
        isEqual(currentFilter.assetValues, assetValues)
    )
}

export const getTime = (time: number): string => {
    if (time === 0) {
        return 'N/A'
    } else if (time < 3600000000000) {
        return Math.round(time / 60000000000) <= 1
            ? `${Math.round(time / 60000000000)} min`
            : `${Math.round(time / 60000000000)} mins`
    } else if (time < 86400000000000) {
        return Math.round(time / 3600000000000) <= 1
            ? `${Math.round(time / 3600000000000)} hour`
            : `${Math.round(time / 3600000000000)} hours`
    } else {
        return Math.round(time / 86400000000000) <= 1
            ? `${Math.round(time / 86400000000000)} day`
            : `${Math.round(time / 86400000000000)} days`
    }
}

export function getFormatedPercentNumber(value: number): string {
    const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2)
    const prefix = value > 0 ? '+' : ''
    return `${prefix}${formattedValue}%`
}
export function getMainMetricsDisplayName(mainMetric: MetricType): string {
    if (mainMetric === MetricType.PROTECTION) {
        return 'Protective Controls'
    } else if (mainMetric === MetricType.MAINTENANCE) {
        return 'Maintenance Processes'
    } else if (mainMetric === MetricType.BEHAVIOUR) {
        return 'Crew Behaviours'
    } else {
        return mainMetric
    }
}

export function getLines(text: string | undefined | null): string[] {
    if (text == undefined) {
        return ['Unknown']
    }
    return text.split('\n')
}
export function determineViewAllAssetsState(
    selected: NodeValue[] | undefined,
): TriStateButtonState {
    if (!selected) {
        return TriStateButtonState.FULLY_SELECTED
    }
    const allAssetValues = [NodeValues.LOW, NodeValues.MEDIUM, NodeValues.HIGH]

    if (selected.length === 0 || allAssetValues.length === 0) {
        return TriStateButtonState.NOT_SELECTED
    } else {
        return allAssetValues.every((item) => selected.includes(item))
            ? TriStateButtonState.FULLY_SELECTED
            : allAssetValues.some((item) => selected.includes(item))
              ? TriStateButtonState.PARTIAL_SELECTION
              : TriStateButtonState.NOT_SELECTED
    }
}
export function isTrendsPeriodNeeded(
    analysisType: AnalysisType,
    selectedViewScreenType: ViewScreenLevelType,
): boolean {
    return (
        analysisType === AnalysisType.TRENDS ||
        (analysisType === AnalysisType.WORST_PERFORMING_VESSELS &&
            selectedViewScreenType === 'Table')
    )
}

export function convertPeriodTimestampForTrends(
    trendsPeriodNeeded: boolean,
    selectedAnalysisPeriod: TimestampFilterType,
): TimestampFilterType {
    const trendsToOtherMapping: {[key in TimestampFilterType]?: TimestampFilterType} = {
        [TimestampFilterType.LATEST]: TimestampFilterType.LAST_2_H,
        [TimestampFilterType.LAST_24_H]: TimestampFilterType.ONE_FORTNIGHT,
        [TimestampFilterType.LAST_7_DAYS]: TimestampFilterType.ONE_QUARTER,
        [TimestampFilterType.LAST_30_DAYS]: TimestampFilterType.ONE_YEAR,
    }

    const otherToTrendsMapping: {[key in TimestampFilterType]?: TimestampFilterType} = {
        [TimestampFilterType.LAST_2_H]: TimestampFilterType.LATEST,
        [TimestampFilterType.ONE_FORTNIGHT]: TimestampFilterType.LAST_24_H,
        [TimestampFilterType.ONE_QUARTER]: TimestampFilterType.LAST_7_DAYS,
        [TimestampFilterType.ONE_YEAR]: TimestampFilterType.LAST_30_DAYS,
    }
    if (trendsPeriodNeeded) {
        return trendsToOtherMapping[selectedAnalysisPeriod] ?? selectedAnalysisPeriod
    } else {
        return otherToTrendsMapping[selectedAnalysisPeriod] ?? selectedAnalysisPeriod
    }
}

export const formatDateNumber = (date: string | number): string => {
    // Check if date is a number and treat it as a Unix timestamp
    if (typeof date === 'number') {
        return moment.unix(date).isValid() ? moment.unix(date).format(DEFAULT_DATE_FORMAT) : 'N/A'
    }
    // Handle string or Moment object
    return date && moment(date).isValid() ? moment(date).format(DEFAULT_DATE_FORMAT) : 'N/A'
}
