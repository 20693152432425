import * as Styled from './vessel-performance.styled'
import {ScoreBar} from './score-bar'
import {VesselPerformance} from './types/metric-report-types'

export function VesselPerformanceWidget({
    vesselPerformance,
}: {
    vesselPerformance: VesselPerformance
}) {
    const {topN, bottomN} = vesselPerformance

    return (
        <>
            <Styled.CategoryWrapper topCategory={true}>
                {topN.map((loc, i) => (
                    <ScoreBar key={i} displayGraph={true} locationPerformance={loc} />
                ))}
            </Styled.CategoryWrapper>
            {bottomN && (
                <>
                    <hr
                        style={{
                            color: '#F7F8F8',
                            borderStyle: 'solid',
                            width: '100%',
                            borderWidth: 'thick',
                        }}
                    />
                    <Styled.CategoryWrapper topCategory={false}>
                        {bottomN.map((loc, i) => (
                            <ScoreBar key={i} displayGraph={true} locationPerformance={loc} />
                        ))}
                    </Styled.CategoryWrapper>
                </>
            )}
        </>
    )
}
