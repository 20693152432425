export function ProtectionIcon() {
    return (
        <svg
            width="42"
            height="40"
            viewBox="0 0 42 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="20.5965" cy="20" rx="20.5965" ry="20" fill="#192CDB" fillOpacity="0.15" />
            <path
                d="M21.5557 27.8393L20.8109 28.1297C20.5424 28.2304 20.2464 28.2304 19.9778 28.1297L19.233 27.8393C17.3465 27.0997 15.7262 25.8098 14.5825 24.1371C13.4389 22.4644 12.8249 20.4864 12.8203 18.4601V14.9508C14.2499 15.1545 15.708 14.9689 17.041 14.4135C18.3739 13.8581 19.5324 12.9535 20.3944 11.7949C21.9723 14.0924 24.5854 15.178 27.9684 14.9508V18.4601C27.9639 20.4864 27.3498 22.4644 26.2062 24.1371C25.0626 25.8098 23.4422 27.0997 21.5557 27.8393Z"
                stroke="#192CDB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
