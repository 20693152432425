import {LocationIdType} from '../../../../../store/state/locations/state'
import {MetricType} from '../../../contexts/types/metrics-response'

export interface AssetStatusCategory {
    totalAssetsOk: number
    totalAssetsWithIssue: number
}

export interface PerformanceCategory {
    aboveTarget: number
    belowTarget: number
}

export interface TrendCategory {
    // ISO datetime
    timestamp: string
    totalAssetsOk: number
    totalAssetsWithIssue: number
}

export interface AssetStatus {
    behaviour: AssetStatusCategory
    maintenance: AssetStatusCategory
    protection: AssetStatusCategory
}

export interface Performance {
    behaviour: PerformanceCategory
    maintenance: PerformanceCategory
    protection: PerformanceCategory
}

export type IssuesRaised = {
    [key in MetricType]: AssetStatusCategory
}

// LocationPerformance is AssetStatusCategory with additional
// totalAssetsOkPercentage and locationId field.
export interface LocationPerformance extends AssetStatusCategory {
    // totalAssetsOkPercentage is the percentage of OK asset over total asset for a location.
    totalAssetsOkPercentage: number
    locationId: LocationIdType
}

export interface VesselMetrics {
    locationId: string
    malwareDetection: AssetStatusCategory
    accessControls: AssetStatusCategory
    securityTools: AssetStatusCategory
    removableControls: AssetStatusCategory
    privilegedAccounts: AssetStatusCategory
    osSupported: AssetStatusCategory
    assetOnline: AssetStatusCategory
    malwareDefinition: AssetStatusCategory
    osSecurityPatches: AssetStatusCategory
    usbStorage: AssetStatusCategory
    mobileDevices: AssetStatusCategory
    hotspotMode: AssetStatusCategory
    wirelessConnection: AssetStatusCategory
    portableSoftware: AssetStatusCategory
    adminLogons: AssetStatusCategory
}

export interface VesselPerformanceWithMetrics {
    topN: VesselMetrics[]
    bottomN: VesselMetrics[]
}
export interface VesselPerformance {
    topN: LocationPerformance[]
    bottomN?: LocationPerformance[]
}

export interface IssueBreakdownByTags {
    tagName: string
    behaviour: number
    maintenance: number
    protection: number
    totalIssue?: number
}

export interface AssetTrend {
    behaviour: TrendCategory[]
    maintenance: TrendCategory[]
    protection: TrendCategory[]
}

export interface Summary {
    assetStatus: AssetStatus
    performance: Performance
    issuesRaised: IssuesRaised
    vesselPerformance: VesselPerformance
    issueBreakdownByTags: IssueBreakdownByTags[]
    assetTrend: AssetTrend
    vesselPerformanceWithMetrics: VesselPerformanceWithMetrics
}

export interface MetricReportApiResponse {
    summary: Summary | null
}

export enum IssueBreakdownByTagsTableHeader {
    TAG_NAME = 'tagName',
    TOTAL_ISSUE = 'totalIssue',
    BEHAVIOUR = 'behaviour',
    PROTECTION = 'protection',
    MAINTENANCE = 'maintenance',
}

export interface SortBy {
    isAsc: boolean
    colIdx: number
}
