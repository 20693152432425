import ActionType from './action-type'
import {Action} from './actions'

import {LoggingOutAction} from '../session-data/actions'
import SessionActionType from '../session-data/action-type'

import {
    DEFAULT_NODES_STATE,
    LatestNodeDetails,
    LocationNodeArrayMapType,
    NodesReduxState,
} from './state'
import {NodeData, NodeDataMap, NodeId} from '../../../values/nodes/NodeData'
import {LocationIdType} from '../locations/state'
import produce from 'immer'
import {isEqual} from 'lodash'

function reduceArrayToMap(
    currentMap: NodeDataMap,
    newNodes: NodeData[],
): {dataUpdated: boolean; data: NodeDataMap} {
    let dataUpdated = false
    const newMap = new Map<NodeId, NodeData>()

    newNodes.forEach((newNode) => {
        const currentNode = currentMap.get(newNode.node)
        if (!currentNode || !isEqual(newNode, currentNode)) {
            dataUpdated = true
            newMap.set(newNode.node, newNode)
        } else {
            newMap.set(currentNode.node, currentNode)
        }
    })

    if (dataUpdated || currentMap?.size !== newMap.size) {
        return {
            dataUpdated: true,
            data: newMap,
        }
    }

    return {
        dataUpdated: false,
        data: currentMap,
    }
}

function getLatestNodeDetails(payload: NodeData[]): LatestNodeDetails | undefined {
    if (!payload || payload.length === 0) {
        return undefined
    }

    const latestNode = payload.reduce((latestNode: NodeData | undefined, node: NodeData) => {
        if (!latestNode) {
            return node
        }

        return node.createdAt > latestNode.createdAt ? node : latestNode
    }, undefined)

    return latestNode ? {id: latestNode.node, timeStamp: latestNode.createdAt} : undefined
}

function reduceArrayToLocationMap(nodes: NodeData[]): LocationNodeArrayMapType {
    return nodes.reduce((dictionary: LocationNodeArrayMapType, node: NodeData) => {
        node.location != undefined && dictionary.has(node.location)
            ? dictionary.get(node.location)?.push(node)
            : dictionary.set(node.location, new Array<NodeData>(node))

        return dictionary
    }, new Map<LocationIdType, Array<NodeData>>())
}

export const nodesReducer = produce(
    (draft: NodesReduxState = DEFAULT_NODES_STATE, action: Action | LoggingOutAction) => {
        switch (action.type) {
            case ActionType.REQUEST_NODES:
                draft.isFetchingNodes = true
                break

            case ActionType.RECEIVE_NODES:
                draft.isFetchingNodes = false

                const {dataUpdated, data} = reduceArrayToMap(draft.nodes, action.payload)
                if (dataUpdated) {
                    draft.nodes = data
                    draft.nodesForLocations = reduceArrayToLocationMap(action.payload)
                    draft.latestNodeDetails = getLatestNodeDetails(action.payload)
                }

                break
            case ActionType.RECEIVE_NODES_FOR_AGENT:
                draft.isFetchingNodes = false
                draft.nodesForAgentForLocations = reduceArrayToLocationMap(action.payload)
                break
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_NODES_STATE
                break

            /* istanbul ignore next */
            default:
                break
        }

        return draft
    },
)
