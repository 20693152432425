import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {showModalSelector} from '../../../../store/state/unsaved-incident-note-alert-modal/selectors'
import {ContentContainerTop} from '../../../../templates/fixed-page/content-container-top.styled'
import {showCards} from '../../data-helpers'
import {AlertModal} from '../shared/alert-modal/alert-modal'
import {Container} from './container.styled'
import {FilterBarButton} from './filter-bar-button/filter-bar-button'
import {RefreshButton} from './refresh/refresh-button'
import {IncidentSearch} from './search/incident-search'
import {ViewScreenLevel} from './view-screen-level/view-screen-level'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const showAlert = useTypedSelector(showModalSelector)
    return (
        <>
            <ContentContainerTop width={width}>
                <Container width={width}>
                    <div style={{display: 'flex', columnGap: '10px'}}>
                        <FilterBarButton />
                        {showCards(width) && <IncidentSearch />}
                    </div>
                    <div style={{display: 'flex', columnGap: '10px'}}>
                        <ViewScreenLevel landingOption={'Table'} />
                        <RefreshButton />
                    </div>
                </Container>
            </ContentContainerTop>
            {showAlert && <AlertModal />}
        </>
    )
}
